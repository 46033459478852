import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import AppRoute from './AppRoute';
import { createStore } from 'redux';import reducer from './store/reducer';
// eslint-disable-next-line no-unused-vars
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {Provider} from 'react-redux';
import outerTheme from '../src/Components/theme/theme'
//import { BrowserRouter as Router} from 'react-router-dom';

//debugger; // TO INSPECT THE PAGE BEFORE 1ST RENDER

const store=createStore(reducer);
ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={outerTheme}>
            <App />
            </ThemeProvider>
        </Provider>,
    document.getElementById('root'));
/* ReactDOM.render(
<Provider store={store}>
    <ThemeProvider theme={outerTheme}>
        <Router><AppRoute /></Router>
        </ThemeProvider>
    </Provider>,
document.getElementById('root')); */
