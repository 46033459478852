import React, {Component} from 'react';
import { connect } from "react-redux";
import * as jsonCall from "../../Algorithms/JSONCall";
//import Toolbar from '@material-ui/core/Toolbar';
//import {makeStyles } from '@material-ui/core/styles';
/* import Button from "@material-ui/core/Button";
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar' */
import { Card, CardGroup} from 'react-bootstrap';
import WindowedSelect from "react-windowed-select";
import Button from 'react-bootstrap/Button';
//import Treemap from "./Treemap"
import Treemap from "../filterArea/Treemap"
import 'bootstrap/dist/css/bootstrap.min.css';
import Attributes from "../attributes/Attributes";
import ProjectionPlotController from "../plotArea/ProjectionPlotController";
import HistogramPlot0 from  "../plotArea/HistogramPlot0";
import UpdateOrderHidden from "../RiskView/UpdateOrderHidden"

/* const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
})); */


export class  CardLeft extends Component {
  //const classes = useStyles();
  constructor(props) {
    super(props);
  
}
componentDidMount() {
    /* if (Object.entries(this.props.cluster_data_filtered).length > 0) {
        this.create_scatter_plot(Object.entries(this.props.cluster_data_filtered))
    } */
   
}
componentDidUpdate() {
    /* if (Object.entries(this.props.cluster_data_filtered).length > 0) {
        this.create_scatter_plot(Object.entries(this.props.cluster_data_filtered))
    } */

}
shouldComponentUpdate(nextProps, nextState){
    return true
}

sort_object = (dict) => {
  var items = Object.keys(dict).map(function (key) {
    return [key, dict[key]];
  });
  items.sort(function (first, second) {
    return second[1] - first[1];
  });
  return items;
}

 



render(){ 
//const { selected_list } = this.state;  
var break_character = <div><br /></div>
return (
    <div>
    
  
  <Card>
  <Card.Header>Select Privacy-related Attributes <UpdateOrderHidden></UpdateOrderHidden></Card.Header>
  <Card.Body>
      <Attributes></Attributes>
  </Card.Body>
  </Card>
  {console.log(this.props.plot_type)}
  {this.props.plot_type === "projection plot"?[0].map((item, index)=>{
      return <Card>
      <Card.Header>Discover Joinable Datasets from Clusters</Card.Header>
      <Card.Body>
          <ProjectionPlotController></ProjectionPlotController>
      </Card.Body>
      </Card>
  }):[0].map((item, index)=>{
    return <Card>
    <Card.Header>Discover datasets with Low Records in an Attribute Category</Card.Header>
    <HistogramPlot0></HistogramPlot0>
    {/* <Card.Body>
        
    </Card.Body> */}
    </Card>
})}
      
  
  
  </div>
    
  );
 } //return ends
}

const maptstateToprop = (state) => {
  return {
      url: state.url,
      attributes_of_interest: state.attributes_of_interest,
      attributes_of_interest_temp: state.attributes_of_interest_temp,
      tags_dict2:state.tags_dict2,
      datasets_with_url: state.datasets_with_url,
      isLoading: state.isLoading,
      isLoadingTags: state.isLoadingTags,
      keywords_datasets_tag: state.keywords_datasets_tag,
      clicked_tags: state.clicked_tags,
      cluster_data: state.cluster_data,
      cluster_data_filtered: state.cluster_data_filtered,
      plot_type: state.plot_type,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      set_selected_datasets: (val) => dispatch({ type: "selected_datasets", value: val }),
      set_final_selected_datasets: (val) => dispatch({ type: "final_selected_datasets", value: val }),
      set_attributes_of_interest: (val) => dispatch({ type: "attributes_of_interest", value: val }),
      set_attributes_of_interest_temp: (val) => dispatch({ type: "attributes_of_interest_temp", value: val }),
      set_projection_data: (val) => dispatch({ type: "projection_data", value: val }),
      set_isLoading: (val) => dispatch({ type: "isLoading", value: val }),
      set_isLoadingTags: (val) => dispatch({ type: "isLoadingTags", value: val }),
      set_dataset_list: (val) => dispatch({ type: "dataset_list", value: val }),
  }
}

export default connect(maptstateToprop, mapdispatchToprop)(CardLeft);
