/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { connect } from "react-redux";
import * as jsonCall from "./../../Algorithms/JSONCall";
import * as d3 from 'd3';
import './treemap.css';

var tags_dict = {};
var tags_dict2 = {};
class Treemap extends Component {
  constructor(props) {
    super(props);
    this.handleTagClick = this.handleTagClick.bind(this)
    //this.createtags = this.createtags.bind(this)
    this.state={a:10}
  }
  componentDidMount(){
    this.setState({a:5})
    jsonCall.download(this.props.url + "/api/v2/tags").then(res =>{
      tags_dict = res["message"]; 
      
      this.setState({tags_dict: tags_dict});
      this.props.set_tags_dict(tags_dict);
    }) 

    jsonCall.download(this.props.url + "/api/v2/tags2").then(res =>{
      tags_dict2 = res["message"]; 
      
      this.setState({tags_dict2: tags_dict2})
      this.props.set_tags_dict2(tags_dict2)
    }) 
    
  }
  componentDidUpdate(){
    /* var self=this
    var top_features=30 //number to features to show
    var tag_frequency_sorted = {}  //gives the keywords and frequency sorted
    var unique_tags_dict = {}  //gives a list of all tags */
    this.createtags(this.state.tags_dict, this.state.tags_dict2);
  }
  handleTagClick=(clicked_tag)=>{
    //this.setState({ selected_list: selected_list })
    console.log(clicked_tag);
    var temp = this.props.clicked_tags;
    if(temp.includes(clicked_tag)){temp = temp.filter(item => item !== clicked_tag);} //removing the tag from the list
    else{temp.push(clicked_tag)}

    var id = "#tags_"+clicked_tag.replace(/\s/g, '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    var element = d3.select(id);
    element.classed("treemap_class_rev", !element.classed("treemap_class_rev"));

    this.props.set_clicked_tags(temp);

  }
  createtags(tags_dict, tags_dict2){
    if(tags_dict !== undefined & tags_dict2 !== undefined){
      var tags_frequency_sorted=this.sort_object(tags_dict).slice(0,30);
      var mycolor = "#d3d3d3";
      var max_occurence = tags_frequency_sorted[0][1]; //the highest frequency

      d3.select('.treemap_container').selectAll('.all_chars')
        //.data(Object.entries(tags_frequency_sorted),d=>d[0])
        .data(tags_frequency_sorted)
        .join('p')
        // eslint-disable-next-line no-useless-escape
        .attr('id',d=>"tags_"+d[0].replace(/\s/g, '').replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''))
        .text(d=>d[0])
        .attr('class',d=>this.props.clicked_tags.includes(d[0])?"all_chars":'treemap_class_rev all_chars') //treemap_class_rev gives the border as if this box is selected
        // eslint-disable-next-line no-useless-concat
        .style("background",d=>'linear-gradient(90deg, ' + mycolor + ' ' + (100 / max_occurence) * d[1] + 'px' + ', transparent 0px)')
        .style('margin',"0.2em")
        .style('width',d=>d[0].length<10?'94px':d[0].length * 7)
        .attr("tagname",d=>d[0])
        .on('click', d=>{
          this.handleTagClick(d[0]);
        });
       
      //d3.selectAll('.tag_keyword').selectAll('.all_chars').data(1).join('p')
    }
  }
  sort_object = (dict) => {
    var items = Object.keys(dict).map(function (key) {
      return [key, dict[key]];
    });
    items.sort(function (first, second) {
      return second[1] - first[1];
    });
    return items;
  }
  //-----------------------------------------------------------------Render function starts here  
  render() {

    return (
      <div className="treemap_container" style={{ width: '100%', height:'83%',  overflowY: "scroll" }}></div>
    );
  }
};
const maptstateToprop = (state) => {
  return {
    url: state.url,
    dataset_list: state.dataset_list,
    tags_dict:state.tags_dict,
    tags_dict2:state.tags_dict2,
    keywords_datasets_tag: state.keywords_datasets_tag,
    keyword_colors: state.keyword_colors,
    clicked_tag_Array: state.clicked_tag_Array,
    clicked_tags:state.clicked_tags,
    cluster_data:state.cluster_data,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    set_dataset_list: (val) => dispatch({ type: "dataset_list", value: val }),
    set_clicked_tags: (val) => dispatch({ type: "clicked_tags", value: val }),
    set_cluster_data_filtered:(val) => dispatch({ type: "cluster_data_filtered", value: val }),
    set_tags_dict:(val) => dispatch({ type: "tags_dict", value: val }),
    set_tags_dict2:(val) => dispatch({ type: "tags_dict2", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(Treemap);
