import React, { Component } from 'react';
import * as d3 from 'd3';
import * as $ from "jquery";
import * as d3lasso from 'd3-lasso';
import { connect } from "react-redux";
import * as jsonCall from "../../Algorithms/JSONCall";
//import Toolbar from '@material-ui/core/Toolbar';
//import {makeStyles } from '@material-ui/core/styles';
import "./plotArea.css";

/* const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
})); */

export default class Histogram extends Component {
  constructor(props) {
    super(props)
    this.state = {
        selected_datasets: {},
        data:{},
    }
  } // end of constructor

  componentDidMount() {
    //this.create_histogram(this.props.agg_data); //DO NOT call create_histogram here since the props are passed through componentsDidUpdate
  }

  componentDidUpdate() {
    
    this.create_histogram(this.props.agg_data); //remember that this is the props send through the call
  }
  shouldComponentUpdate(nextProps, nextState){
    return true
  }

  /* create_histogram = (data) => {
    console.log(data);

    
    var margin = { top: 30, right: 10, bottom: 30, left: 80 }, //top: 10, right: 80, bottom: 30, left: 60
    width = 560 - margin.left - margin.right,
    height = 530 - margin.top - margin.bottom;//250

    // append the svg object to the body of the page
    var svg = d3.select("#my_dataviz_agg")
        .attr("width","100%")
        //.attr("height","100%")
        //.attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
    width = parseInt((svg.style("width")).replace("px",""))// - margin.left - margin.right; 
    //height = parseInt((svg.style("height")).replace("px","")) - margin.top - margin.bottom; 
    
    
  } */

  create_histogram = (data) => { // data is {key,value} key is the x values and value is the y
    var full_width = $("#grid_" +this.props.index_big +"_"+ this.props.index).width();
    var margin = { top: 30, right: 20, bottom: 70, left: 30 },
    //var margin = { top: 10, right: 0, bottom: 70, left: 30 },
        width = $("#grid_" +this.props.index_big +"_"+ this.props.index).width() - margin.left - margin.right,
        height = 250 - margin.top - margin.bottom;

    var svg = d3.select("#" + this.props.myid).attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom) //----------------------------- Get svg x scale and y scale
    var x = d3.scaleBand().domain(Object.keys(data)).range([0, width]).padding(0.01)
    var y = d3.scaleLinear().domain([0, d3.max(Object.values(data))*1.1]).range([height, 0]);
    
    var xAxis = d3.select("#" + "myXaxis" + this.props.myid).attr("class", "myXaxis1").attr("transform", "translate(" + margin.left + "," + height + ")") //-------------------------------------------------- X axis
    xAxis.call(d3.axisBottom(x))

    var yAxis = d3.select("#" + "myYaxis" + this.props.myid).attr("class", "myYaxis").attr("transform", "translate(" + margin.left + "," + "0" + ")") //--------------------------------------------------Y axis
    yAxis.call(d3.axisLeft(y).ticks(5));

    var max_y = d3.max(Object.values(data));
    //var threshold = 0.02 * max_y; // threshold is set to 2 % of max value
    var threshold = this.props.threshold_k;

    var bars = svg.selectAll("rect").data(Object.entries(data), d => d[0]) //------------------------------------------------------------------------------ Drawing starts here
    bars.join(
        enter =>
            enter.append("rect")
                .attr("x", function (d) {
                    return x(d[0]) + margin.left
                })
                .attr("y", function (d) { return y(d[1]) })
                .attr("class", "bar")
                .attr("width", x.bandwidth())
                .attr("height", function (d) { return height - y(d[1]) })
                .attr("fill", function (d) {if(d[1]<= threshold){return "red"} else{return "#969696"}}),//  #5f89ad  #69b3a2
        update =>
            update
                .transition()
                .duration(750)
                .attr("x", function (d) {
                    return x(d[0]) + margin.left
                })
                .attr("y", function (d) { return y(d[1]) })
                .attr("class", "bar")
                .attr("width", x.bandwidth())
                .attr("height", function (d) { return height - y(d[1]) })
                .attr("fill", function (d) {if(d[1]<= threshold){return "red"} else{return "#969696"}}) //#5f89ad
    );

    var labels = svg.selectAll("text.bar").data(Object.entries(data), d => d[0])
    labels.join(
        enter =>
            enter.append("text")
            .attr("class", "bar")
            .attr("text-anchor", "middle")
            .attr("x", function(d) { return x(d[0]) + margin.left + x.bandwidth()/2; })
            .attr("y", function(d) { return y(d[1]) -7; })
            .attr("font-size",Math.ceil(0.04*full_width)+"px") //0.028*full_width
            .text(function(d) { return d[1]; }),
        update =>
                update
                .transition()
                .duration(750)   
    );

    d3.selectAll(".myXaxis1").selectAll('text')
        .attr("transform", function (d) {
                return "rotate(-90)";
        })
        .text(function(d,i){return d.length>13?d.substring(0,13)+'...':d})

        .attr("dx",(d,i)=> {
                return margin.top-82//130   92
        })
        .attr("dy", "0.1em")
        //.style("text-anchor", "start")

}
 
  /* create_histogram = (datum) => {
    var threshold = 3;
    const margin = {top: 30, right: 30, bottom: 70, left: 60},
    width = 300 - margin.left - margin.right,
    height = 250 - margin.top - margin.bottom;
console.log(datum);
// append the svg object to the body of the page
const svg = d3.select("#my_dataviz_agg")
  .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
  .append("g")
    .attr("transform", `translate(${margin.left},${margin.top})`);

// Parse the Data

// X axis
const x = d3.scaleBand()
  .range([ 0, width ])
  .domain(Object.keys(datum))
  .padding(0.2);
svg.append("g")
  .attr("transform", `translate(0, ${height})`)
  .call(d3.axisBottom(x))
  .selectAll("text")
    .attr("transform", "translate(-10,0)rotate(-45)")
    .style("text-anchor", "end");

// Add Y axis
const y = d3.scaleLinear()
  .domain([0, d3.max(Object.values(datum))*1.1])
  .range([ height, 0]);
svg.append("g")
  .call(d3.axisLeft(y));

// Bars
svg.selectAll("mybar")
  .data(Object.entries(datum), d => d[0])
  .join("rect")
    .attr("x", d => x(d[0]))
    .attr("y", d => y(d[1]))
    .attr("width", x.bandwidth())
    .attr("class", "bar")
    .attr("height", d => height - y(d[1]))
    .attr("fill",(d) => {if(d[1]<= threshold){return "red"} else{return "#5f89ad"}} )

  var labels = svg.selectAll("text.bar").data(Object.entries(datum), d => d[0])  
  labels.join("text")
  .attr("class", "bar")
  .attr("text-anchor", "middle")
  .attr("x", function(d) { return x(d[0]) + x.bandwidth()/2; })
  .attr("y", function(d) { return y(d[1]) -7; })
  .attr("font-size","8px")
  .text(function(d) { return d[1]; })


  } */  
render() {
    return (
      <div>
                <p style={{ marginLeft: "45%", marginTop: -8 }}>{this.props.title}</p>
                <svg id={this.props.myid}>
                    <g id={"myXaxis" + this.props.myid}></g>
                    <g id={"myYaxis" + this.props.myid}></g>
                </svg>

            </div>
    )
  }// end of render
} //end of class

