import React, {Component} from 'react';
import { connect } from "react-redux";
import * as jsonCall from "../../Algorithms/JSONCall";
//import { Card, CardGroup} from 'react-bootstrap';
import WindowedSelect, { components } from "react-windowed-select";
//import Select, { components } from 'react-select';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


export class  Tags extends Component {
  //const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {selected_list: []};
    this.handleListChange = this.handleListChange.bind(this);
  
}
componentDidMount() {
  jsonCall.download(this.props.url + "/api/v2/tags").then(res =>{
    var tags_dict={}
    if(typeof res != "undefined"){tags_dict = res["message"]; }
    this.setState({tags_dict: tags_dict});
    this.props.set_tags_dict(tags_dict);
  }) 

  jsonCall.download(this.props.url + "/api/v2/tags2").then(res =>{
    var tags_dict2={}
    if(typeof res != "undefined"){tags_dict2 = res["message"]; }
    this.setState({tags_dict2: tags_dict2})
    this.props.set_tags_dict2(tags_dict2)
  }) 
   
}
componentDidUpdate() {
}
shouldComponentUpdate(nextProps, nextState){
    return true
}

handleListChange=(selected_tags_list)=>{
    this.setState({ selected_list: selected_tags_list })
    var temp = selected_tags_list.map(d=>d.value);
    //this.props.set_attributes_of_interest_temp(temp);
    this.props.set_clicked_tags(temp);
}
  
 
 
sort_object = (dict) => {
  var items = Object.keys(dict).map(function (key) {
    return [key, dict[key]];
  });
  items.sort(function (first, second) {
    return second[1] - first[1];
  });
  return items;
}



render(){ 
const { selected_list } = this.state;  
var tags_dict = this.props.tags_dict;
var tags_dict2 = this.props.tags_dict2;
console.log(tags_dict, tags_dict2);
if(tags_dict !== undefined & tags_dict2 !== undefined){// & Object.keys(tags_dict).length >0 & Object.keys(tags_dict2).length >0){
  var tags_frequency_sorted=this.sort_object(tags_dict).slice(0,30);
  var mycolor = "#d3d3d3";
  var max_occurence = (tags_frequency_sorted[0] === undefined)?73:tags_frequency_sorted[0][1]; //the highest frequency //weird error: if a default value is not set, the interface is not rendered initially
  //var max_occurence = 73;
  var to_be_shown = tags_frequency_sorted.map(d =>d[0]);
  console.log(to_be_shown);
  var options = [];
  for (let i = 0; i < to_be_shown.length; i += 1) {
    options.push({
      label: to_be_shown[i],
      value: to_be_shown[i],
      frequency:tags_frequency_sorted[i][1]
    });
  }

  const customStyles = {
    /* option: (provided, state) => ({
      ...provided,
      borderBottom: '2px dotted green',
      color:  'yellow',
      backgroundColor: 'green' 
    }), */
    option: (styles, { data }) => { //for the drop down
      var freq = data.frequency;
      return {
        ...styles,
        background: 'linear-gradient(90deg, ' + mycolor + ' ' + (400 / max_occurence) * freq + 'px, transparent 0px)',
        border: "0.1px solid white",
        //fontSize: ".75em",
        //color: "rgb(0, 0, 0)",
        //padding: "0px 3px",
        
      }},
    multiValue: (styles, { data }) => { //for the selected values
    var freq = data.frequency;
    var width = data.label.length<10?'94px':data.label.length * 7;
    console.log(max_occurence,freq);  
    return {
      ...styles,
      //background: 'red',
      background: 'linear-gradient(90deg, ' + mycolor + ' ' + (100 / max_occurence) * freq + 'px, transparent 0px)',
      width: width,
      border: "1px solid #d3d3d3",
      borderRadius: "5px",
      //fontSize: ".75em",
      color: "rgb(0, 0, 0)",
      padding: "0px 3px",
      //background: 'linear-gradient(90deg, #d3d3d3 40px, transparent 0px)'
      
    }}
  }

return (
    <div>
    <WindowedSelect
                      options={options}
                      onChange={this.handleListChange}
                      styles = {customStyles}
                      value={selected_list}
                      placeholder="Select tags ..."
                      isMulti
                      closeMenuOnSelect={false}

                    />
   

  </div>
    
  );}
 } //return ends
}

const maptstateToprop = (state) => {
  return {
      url: state.url,
      attributes_of_interest_temp: state.attributes_of_interest_temp,
      isLoading: state.isLoading,
      attributes_of_interest: state.attributes_of_interest,
      tags_dict: state.tags_dict,
      tags_dict2:state.tags_dict2,
      
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      set_attributes_of_interest_temp: (val) => dispatch({ type: "attributes_of_interest_temp", value: val }),
      set_isLoading: (val) => dispatch({ type: "isLoading", value: val }),
      set_attributes_of_interest: (val) => dispatch({ type: "attributes_of_interest", value: val }),
      set_projection_data: (val) => dispatch({ type: "projection_data", value: val }),
      set_tags_dict: (val) => dispatch({ type: "tags_dict", value: val }),
      set_tags_dict2: (val) => dispatch({ type: "tags_dict2", value: val }),
      set_clicked_tags: (val) => dispatch({ type: "clicked_tags", value: val }),
      
  }
}

export default connect(maptstateToprop, mapdispatchToprop)(Tags);
