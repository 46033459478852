export function download(myurl, data) {
  return fetch(myurl, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => res.json())
    .then(response => {
      return response;
    }).catch(error => console.error('Error: from Json Handler', error));
}
export function mydownlaod(myurl, data) {
  console.log('called')
  return fetch(myurl, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(res => console.log(res.json()))
    .then(response => {
      return response;
    }).catch(error => console.error('Error: from Json Handler', error))
  }
