/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { connect } from "react-redux";
import Item from "./Item";
import * as $ from "jquery";
import * as d3 from "d3";
import Radio from './Radio';
import _ from 'lodash';

class Test extends Component {
    constructor(props) {
        super(props)
        this.state = { temp: 1 }
    }
    componentDidMount() {
        this.setState({ temp: 0 })
    }
    componentDidUpdate(prevProps, prevState) {
    }
    render() {
        console.log("Loading End: All components loaded");
        $(".divLoader").hide();
        
        return null;
    }
  
};
const maptstateToprop = (state) => {
    return {
        url:state.url,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        set_checked_datasets: (val) => dispatch({ type: "checked_datasets", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(Test);