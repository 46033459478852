import React, {Component} from 'react';
import { connect } from "react-redux";
import * as jsonCall from "../../Algorithms/JSONCall";
//import { Card, CardGroup} from 'react-bootstrap';
import WindowedSelect from "react-windowed-select";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as d3 from "d3";
import Grid from '@material-ui/core/Grid';



var attributes_list1 = ['age', 'gender', 'race'];
//var attributes_list2 = ["age_group", "vic_age_group", "susp_age_group", "perp_age_group", "vict_age","age_1", "age_at_release", "admission_age", "age_class", "subject_age", "patient_age", "age_range", "offender_age", "officer_age", "age_at_arrest","sex", "susp_sex", "vic_sex", "perp_sex","sex_1",  "victim_gender", "suspect_gender", "complainant_sex", "officers_sex",  "susp_race", "vic_race", "perp_race", "vict_descent",  "victim_race", "suspect_race", "complainant_race", "officers_race","subject_race", "officer_race"]
var attributes_list2 = ["agegroup", "vicagegroup", "suspagegroup", "perpagegroup", "victage", "agerange","age1", "ageatrelease", "ageatincident", "admissionage", "ageinyears","ageclass", "subjectage", "patientage", "agerange", "victimage","offenderage", "officerage", "ageatarrest","agegrp", "agegrp", "agecategory", "agecat","agedecade", "ageunits",  "patientage","decedentage","agecount","sex", "suspsex", "vicsex", "perpsex","sex1",  "victimgender","offendergender","suspectgender", "complainantsex", "officerssex", "birthgender", "totalgender", "genderidentity","genderdesc","gendercount","susprace", "ethnicity", "vicrace", "perprace", "victdescent",  "sexualorientation",  "whatisyourgender", "whatisyoursex","race1", "raceother1","victimrace","offenderrace", "suspectrace", "complainantrace", "officersrace","subjectrace", "officerrace", "race09", "race05", "raced", "raceg",  "racec", "racej","race4","race3", "racek", "raceid",  "twoormoreraces","whatisyourrace",  "ethnicitydesc", "racef", "racel", "racecat2", "racem","race2",  "race04", "race08", "race01", "racen",  "raceh", "race06", "race02", "race07", "race5", "raceb","racea", "raceethnicitynativeamerican", "americanindianalaskanativerace","raceethnicity","raceethnicity", "raceethnicityother1","raceethnicitywhite", "raceethnicityblack",  "racedesc"];
//var attributes_list3 = [ "sex", "ethnicity", "race09", "hhadultgender5", "agegrp",  "children_ages_0_5", "race1", "race_other1", "age_at_incident", "race_hispanic_origin", "hhadultage2", "birthgender", "total_race", "what_is_your_race_or_ethnicity", "total_gender", "race05", "race_d", "race_g", "age_range", "age_decade", "age_group", "race_c",  "hhadultage5", "race_ethnicity_native_american", "gender_identity", "age_group_option_1", "selfgender", "race4", "ethnicity_desc", "race_f", "race_l", "race_cat2", "race_m", "age_grp", "hhadultgender2", "what_is_your_gender", "age_units", "race_ethnicity", "race_ethnicity_other_1", "decedent_age", "race_j", "highly_engaged", "age_category", "hhadultgender3", "race2", "tobaccomessages", "race3", "race_k", "race_id",  "two_or_more_races", "sexualorientation",  "age_specific_rate", "race04", "race08", "americanindianalaskanativerace", "sexualorientation_os", "mom_s_race", "age_adjusted_rate",  "race_n",  "race_h", "race06", "race02", "race07", "what_is_your_sex", "asianrace", "race5", "race_b", "race_ethnicity_white", "race_ethnicity_black",  "race_desc", "age_adjusted_rate_standard_error", "agecat",  "race01",  "genderc", "patient_age", "age_sex_race", "agedisplay", "race_ethnicity_other", "race_ethnicity_hispanic",  "gender_desc", "race_other2", "age_decade_squared", "what_is_your_race", "age_count", "raceethn", "race_a", "average_local_salary", "age_group_option_3", "gender_count", "age_range_on_date", "age_in_years", "race_i",  "agedesc", "average_years_of_age", "race_count", "raceethnicity", "square_root_of_age", "ageoth"]
var options = [];
for (let i = 0; i < attributes_list1.length; i += 1) {
  options.push({
    label: attributes_list1[i],
    value: attributes_list1[i]
  });
}
var selected_list_initial = [...options];
for (let i = 0; i < attributes_list2.length; i += 1) {
  options.push({
    label: attributes_list2[i],
    value: attributes_list2[i]
  });
}

export class  Attributes extends Component {
  //const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {selected_list: selected_list_initial};
    this.handleListChange = this.handleListChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  
}
componentDidMount() {
    
   
}
componentDidUpdate() {
   

}
shouldComponentUpdate(nextProps, nextState){
    return true
}

handleListChange=(selected_attributes_of_interest)=>{
    this.setState({ selected_list: selected_attributes_of_interest })
    var temp = selected_attributes_of_interest.map(d=>d.value);
    this.props.set_attributes_of_interest_temp(temp)
}
  
handleButtonClick=()=>{
    this.props.set_isLoading(true);
    console.log(this.props.attributes_of_interest);
    if(this.props.plot_type === "projection plot"){
      jsonCall.download(this.props.url + "/api/v2/projection2", {attributes_of_interest: this.props.attributes_of_interest_temp, dataset_type:this.props.dataset_type,selected_portals:this.props.selected_portals}).then(res =>{d3.selectAll(".attributes_xaxis").remove();this.props.set_sorted_clusters(res["sorted_clusters"]);this.props.set_projection_data(res["message"]); this.props.set_attributes_of_interest(this.props.attributes_of_interest_temp); this.props.set_isLoading(false);})}
    else{
        jsonCall.download(this.props.url + "/api/v2/histograms", {dataset_type:this.props.dataset_type,selected_portals:this.props.selected_portals}).then(res =>{d3.selectAll(".attributes_xaxis").remove();this.props.set_agg_data(res);this.props.set_attributes_of_interest(this.props.attributes_of_interest_temp);this.props.set_isLoading(false);})
        
    }
    //jsonCall.download(this.props.url + "/api/v2/projection2", {attributes_of_interest: this.props.attributes_of_interest_temp}).then(res =>{this.props.set_projection_data(res["message"]); this.props.set_attributes_of_interest(this.props.attributes_of_interest_temp); this.props.set_isLoading(false);})
}  
 



render(){ 
const { selected_list } = this.state;  
//var break_character = <div><br /></div>

const customStyles = {
  multiValue: (styles, { data }) => {
  return {
    ...styles,
    backgroundColor: '#F78787',
    //border: "1px solid #d3d3d3",
    //borderRadius: "5px",
    //fontSize: ".75em",
    color: "rgb(0, 0, 0)",
    //padding: "0px 3px",
    //background: 'linear-gradient(90deg, #d3d3d3 40px, transparent 0px)'
    
  }}
}
return (
    <Grid container row spacing={2}>
    <Grid item xs={10} sm={10}><WindowedSelect
                      options={options}
                      onChange={this.handleListChange}
                      styles = {customStyles}
                      value={selected_list}
                      isMulti
                      closeMenuOnSelect={false}

                    />
   </Grid>
   <Grid item xs={2} sm={2}>
   <Button
      variant="secondary"
      disabled={this.props.isLoading}
      onClick={!this.props.isLoading ?this.handleButtonClick : null}
      > {this.props.isLoading ? 'Loading…' : 'Update'}
    </Button>
    </Grid>
  </Grid>
    
  );
 } //return ends
}

const maptstateToprop = (state) => {
  return {
      url: state.url,
      attributes_of_interest_temp: state.attributes_of_interest_temp,
      isLoading: state.isLoading,
      attributes_of_interest: state.attributes_of_interest,
      plot_type: state.plot_type,
      dataset_type: state.dataset_type,
      selected_portals: state.selected_portals,
      
      
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      set_attributes_of_interest_temp: (val) => dispatch({ type: "attributes_of_interest_temp", value: val }),
      set_isLoading: (val) => dispatch({ type: "isLoading", value: val }),
      set_attributes_of_interest: (val) => dispatch({ type: "attributes_of_interest", value: val }),
      set_projection_data: (val) => dispatch({ type: "projection_data", value: val }),
      set_agg_data: (val) => dispatch({ type: "agg_data", value: val }),
      set_sorted_clusters: (val) => dispatch({ type: "sorted_clusters", value: val }),
      
  }
}

export default connect(maptstateToprop, mapdispatchToprop)(Attributes);
