import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Dialog, capitalize } from '@material-ui/core';
export default function Dialogs(props) {
    const useStyles = makeStyles({
        dialog: {
            minWidth: 150,
            position: 'absolute',
            left: props.dialogue_data['x'],
            top: props.dialogue_data['y'],
        }
    });
    const classes = useStyles();
    const handleClose = () => {
        props.set_dialogue_data({ ...props.dialogue_data, open: false })
    };
    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.dialogue_data['open']} classes={{
                paper: classes.dialog
            }}>
                <div>
                <Button autoFocus onClick={handleClose} color="primary" onClick={()=>{handleClose();props.set_table_open(true)}}>
                    Show Data
                </Button>
                <Button autoFocus onClick={handleClose} style={{color:'red'}}>
                    Cancel
                </Button>
                </div>
            </Dialog>
        </div>
    );
}
