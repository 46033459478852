/* eslint-disable array-callback-return */
import { Component } from 'react';
import { connect } from "react-redux";
//import _ from 'lodash';

class Test extends Component {
    constructor(props) {
        super(props)
        this.state = { temp: 1 }
    }
    componentDidMount() {
        this.setState({ temp: 0 })
    }
    componentDidUpdate(prevProps, prevState) {
    }
    render() {
        console.log("Update End: All components loaded after update");
        this.props.set_isLoadingUpdate(false);
        
        return null;
    }
  
};
const maptstateToprop = (state) => {
    return {
        url:state.url,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        set_checked_datasets: (val) => dispatch({ type: "checked_datasets", value: val }),
        set_isLoadingUpdate: (val) => dispatch({ type: "isLoadingUpdate", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(Test);