import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


function BasicTable(props) {
  const data = props.table_data
  const classes = useStyles();
  var header_data = Object.keys(data[0]).sort((a, b) => props.attributes_for_match_view.indexOf(b) - props.attributes_for_match_view.indexOf(a))
  return (
<div style={{ height: "100%"}}>
<Button style={{ color: 'red', backgroundColor: 'white', marginLeft: '46%', marginBottom: 5,position:'sticky',top:5 }} onClick={()=>props.set_table_open(false)}>Close</Button>
<TableContainer component={Paper} style={{ height: "95%", overflow: "scroll", position: 'relative' }}>
      <Table stickyHeader className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {header_data.map(item => <TableCell onClick={()=>props.set_attributes_for_match_view([...props.attributes_for_match_view,item])} align="center" style={{ color:props.attributes_for_match_view.includes(item)?"#f44949":"black", cursor:"pointer", backgroundColor: 'rgb(206,206,206)', textTransform: 'capitalize', fontWeight: 600, fontSize: 15 }}>{item}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow>
              {header_data.map(item => <TableCell align="center">{row[item]}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
</div>
  );
}
const maptstateToprop = (state) => {
  return {
    attributes_for_match_view: state.attributes_for_match_view,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    set_attributes_for_match_view: (val) => dispatch({ type: "attributes_for_match_view", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(BasicTable);