import * as d3 from "d3"
import * as $ from "jquery"
import * as _ from "lodash";
import createBins from './CreateBin'
import "./matches.css";
export default function CreateHistogramNum(datum, svg, item, item_width, item_height, line_data, item_index,attributes_of_interest) {
  //console.log(item,datum,'item')
  var baseline = 100
  const margin = { top: 30, right: 10, bottom: 40, left: 100 }; //margin.left should be 40
  var width = item_width - margin.left - margin.right;
  var height = item_height - margin.top - margin.bottom;
  var item_data = datum.map(element => parseInt(element[item]))
  var x_position=item_width*item_index
  const y = d3.scaleLinear().domain(d3.extent(item_data)).range([margin.top, height])
  // set the parameters for the histogram
  var binned_data=createBins(item_data, 4)
  var myticks = binned_data.map(item=>item["x1"])

  var ticks = myticks
  //-------
  line_data[item] = binned_data.map(item => [item.x0, item.x1])
  //-------
  svg.select(".title_text").attr('x',baseline/2)
  svg.selectAll(".temp_rect").data([0]).join('rect').attr("class", "temp_rect").attr("width", baseline).attr('x',0).attr('y',margin.top).attr("height", height+100).style("stroke", "black").style("fill", "white").style("stroke-width", 0);

  svg.selectAll(".border_rect").data([0]).join('rect').attr("class", "border_rect").attr("width", baseline).attr('x',0).attr('y',margin.top).attr("height", height+10).style("stroke", "black").style("fill", "white").style("stroke-width", 0.1);
  //console.log(binned_data,item_data,'binned_data')
  svg.append("g").attr("transform", 'translate(' + baseline + ',0)').call(d3.axisRight(y).tickValues(ticks)).selectAll('text').attr("id", d => item+"id_" + d).attr('id_value',d => d).attr('font-size',16)
    .attr("dummy", function () {
      d3.select(this).attr('yPosition',(d,i)=> 
      {
        var take_up=(y(binned_data[0].x1)-y(binned_data[0].x0))/2
        var temp = parseInt(d3.select(this.parentNode).attr('transform').replace(/[&\/\\#+()$~%'":*?<>{}]/g, '').split(",")[1])
        return temp - take_up
      })
      .attr('x1Position', item_index * item_width+15).attr('x2Position', item_index * item_width + baseline - 10)
    })
    .attr('remove_domains_tick_lines',function(){d3.select(this.parentNode).selectAll('line').remove();d3.select(this.parentNode.parentNode).selectAll('.domain').remove()})



  //---------
  var data = []
  var binned_data=createBins(item_data, 20)
  binned_data.map((item, i) => data.push([i, item['count']]))
  //const data = [[0, 0], [1, 80], [2, 20], [3, 210], [4, 130], [5, 270], [6, 30], [7, 110], [8, 130], [9, 0]];
  var xScale = d3.scaleLinear().domain([0, d3.max(data.map(item=>item[1]))]).range([baseline, 0]) // 20 is the baseline
  var yScale = d3.scaleLinear().domain(d3.extent(data.map(item => item[0]))).range([0, height])
  const areaGenerator = d3.area()
    .x0(baseline)
    .x1(d => xScale(d[1]))
    .y(d => yScale(d[0])) // This is the x values
    .curve(d3.curveMonotoneY)
  svg.append("path").attr('transform', 'translate(0,' + margin.top + ')')
    .attr("d", areaGenerator(data))
    .style("fill", (d) =>(attributes_of_interest.includes(item))?"#F78787":"rgb(210,210,210)");
    svg.selectAll(".graph_lines").data(ticks).join('line').attr("class", "graph_lines").attr("x1", 0).attr("x2", baseline).attr("y1",d=>y(d)).attr("y2", d=>y(d)).attr("stroke-width", 0.1).attr("stroke", "black").raise()
  }




export function CreateHistogramCat(datum, svg, item, item_width, item_height, line_data, item_index,number_of_attributes,attributes_of_interest) {
  const margin = { top: 30, right: 10, bottom: 10, left: 0 }; //margin.left should be 40
  var width = item_width - margin.left - margin.right;
  var height = item_height - margin.top - margin.bottom;
  var available_width = width / 2;
  var domain_higher_end = datum.map(d => d[item]).length;
  

  const x2 = d3.scaleLinear().range([0, available_width]).domain([0, 1]);
  var freq = Object.entries(_.countBy(datum.map(d => d[item])));
  //if(freq.length>=20 && number_of_attributes === 1){
  if(freq.length>=20){  
    freq = freq.slice(0,20);
    freq.push(["...",1]);
    var high = freq.map(d=>d[1]);
    domain_higher_end = _.sum(high);
  }
  //-------
  line_data[item] = freq.map(item => [item[0], item[0]])
  //-------
  const y2 = d3.scaleLinear()
    .domain([0, domain_higher_end]) //can make this scaleLinear and then mark thr bars/rect //customizing the higher end if the number of bins are low
    .range([margin.top, height]);

  var tooltip = d3.select("body").append("div")
    .attr("class", "tooltip_categories")
    .style("opacity", 0);  

  var height_plotted = margin.top;
  svg.selectAll(".rect2")
    .data(freq)
    .join("rect")
    .attr("class", "rect2")
    .attr("y", 0)
    .attr("transform", function (d, i) {
      var position = height_plotted;
      height_plotted += y2(d[1]) - margin.top;
      var temp_text=d[0].length>15?d[0].substring(0,15)+"...":d[0];
      d3.select(this.parentNode).selectAll(".text3" + i).data([0]).join("text").attr('class', 'text3' + i).text(((y2(d[1])/y2(domain_higher_end))>0.07)?temp_text:null).attr('dominant-baseline', 'middle').style("f",function(){return 1;})
        .attr('y', position + (y2(d[1]) - margin.top) / 2).attr('x', available_width / 2).attr('text-anchor', 'middle')
      d3.select(this).attr('yPosition', position + (y2(d[1]) - margin.top) / 2).attr('x1Position', item_index * item_width).attr('x2Position', item_index * item_width + available_width)
      return `translate(${0},${position})`
    })
    .attr('id', d => {return (d[0] !== "...")?item+'id_' + d[0].replace(/ /g, '').replace(/[&\/\\,+()$~%.'":*?<>{}]/g, ''):item+"id_othersdotdotdot"})
    .attr('id_value',d => d[0])
    .attr("width", function (d) { return x2(1) }) // width - x(d.length)
    .attr("height", function (d) { return y2(d[1]) - (margin.top+0.5) }) // height - margin.top -y(d[1])
    .style("fill", (d) =>(attributes_of_interest.includes(item))?"#F78787":"rgb(210,210,210)")
    .on("mouseover", function (d) {
      if(number_of_attributes !== 1){
      tooltip.transition()
        .duration(200)
        .style("opacity", .9);
      tooltip.html(d[0])
        .style("left", (d3.event.pageX + 5) + "px")
        .style("top", (d3.event.pageY - 28) + "px");
      }
      })
      .on("mouseout", function (d) {
      tooltip.transition()
        .duration(500)
        .style("opacity", 0);  
      })
    //.attr('stroke', 'black')
    d3.select(this).selectAll('.domain').remove()
}

//https://stackoverflow.com/questions/54236051/how-to-draw-a-vertical-area-chart