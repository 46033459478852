/* eslint-disable array-callback-return */

import React, { Component } from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import AppBar from "./Components/appbar/AppBar";
//import FilterBar from "./Components/filterArea/FilterBar";
//import Plots from "./Components/plotArea/Plots";
import MatchesView from "./Components/MatchesView/MatchesView3";
//import { Filter } from '@material-ui/icons';
//import RiskView from "./Components/RiskView/RiskView"
import CardLeft from "./Components/layouts/CardLeft";
import CardRight from "./Components/layouts/CardRight";
import * as jsonCall from "./Algorithms/JSONCall";
import * as d3 from "d3";
import databankv5_combined from "./data/databankv5_combined.csv";
import entropy_results from "./data/entropy_results.json";
//import risk_scores_only from "./data/risk_scores_only_m.json";
import "./App.css"
import PopUp from "./Components/MatchesView/PopUp/PopUp";
import tags_dict_result from "./tags_dict_result.json"
import tags_dict_result2 from "./tags_dict2_result.json"


class App extends Component {
  constructor(props) {
    super(props)
    this.state = { mydata: null, filtered_shared_attributes: [], combination: "Age_Gender", datasets_basedon_tags: null }
  }
  componentDidMount() {
    d3.csv(databankv5_combined).then(data => {
      var temp_original_data = {}
      data.map(item => {
        item['Columns Field Name'] = JSON.parse(item["Columns Field Name"].replace(/'/g, '"'));
        item['Columns Name'] = JSON.parse(item["Columns Name"].replace(/'/g, '"'));
        temp_original_data[item["Dataset"]] = item
        //temp_original_data.push(item)
      })
      this.props.set_original_data(temp_original_data)
    })
    //this.props.set_risk_scores_total(risk_scores_only);
    this.props.set_entropy_results(entropy_results);
    jsonCall.download(this.props.url + "/api/v2/tags").then(res =>{
      var tags_dict={}
      if(typeof res=='undefined'){tags_dict=tags_dict_result}
      else{tags_dict = res["message"]} 
      //console.log(JSON.stringify(tags_dict),"tags_dict")
      this.setState({tags_dict: tags_dict});
      this.props.set_tags_dict(tags_dict);
    }) 
  
    jsonCall.download(this.props.url + "/api/v2/tags2").then(res =>{
      var tags_dict2={}
      if(typeof res=='undefined'){tags_dict2=tags_dict_result2}
      else{tags_dict2 = res["message"]}
      this.setState({tags_dict2: tags_dict2})
      this.props.set_tags_dict2(tags_dict2)
    }) 
    
  }



  //---------------------------------------------------------------------------------------------------------------------------------------


  classAdder = (id, class_name) => {
    var element = document.getElementById(id);
    element.classList.add(class_name);
  }
  classRemover = (id, class_name) => {
    var element = document.getElementById(id);
    element.classList.remove(class_name);
  }


  render() {
    console.log(this.props.table_open)
    //var break_character = <div><br /></div>

    
    return (
      <div>
        {/* <Grid container spacing={0}>
          <Grid item xs={12}><AppBar></AppBar></Grid>
          <Grid item xs={12}><FilterBar></FilterBar></Grid>
          <Grid item xs={12}>{break_character}</Grid>
          <Grid item xs={12}><Plots></Plots></Grid>
          <Grid item xs={12}>{break_character}</Grid>
          {this.props.attributes_for_match_view.length>0?<Grid key={this.props.attributes_for_match_view.length} item xs={12}><MatchesView></MatchesView></Grid>:null}
        </Grid> */}
        <Grid container spacing={0}>
        <Grid item xs={12}><AppBar style={{height:"5vh"}}></AppBar></Grid>
        <Grid item xs={12}>
          <Grid container>
          <Grid item xs={12} lg={5}><CardLeft></CardLeft></Grid>
          <Grid item xs={12} lg={7}><CardRight></CardRight></Grid>
          </Grid>
        </Grid>
        {this.props.attributes_for_match_view.length>0?<Grid key={this.props.attributes_for_match_view.length} item xs={12}><MatchesView></MatchesView></Grid>:null}
        </Grid>
        {this.props.table_open === true ? <PopUp attributes_of_interest={this.props.attributes_of_interest} table_data={this.props.table_data} table_open={this.props.table_open} set_table_open={this.props.set_table_open}></PopUp> : null}
      </div>
    );
  }
};
const maptstateToprop = (state) => {
  return {
    attributes_for_match_view:state.attributes_for_match_view,
    attributes_of_interest: state.attributes_of_interest,
    table_data: state.table_data,
    table_open: state.table_open,
    checkbox_attributes: state.checkbox_attributes,
    url: state.url,
    filters: state.filters,
    original_data: state.original_data,
    original_data2: state.original_data2,
    original_data3: state.original_data3,
    dict_of_tags_basedon_keyword: state.dict_of_tags_basedon_keyword,
    tag_count: state.tag_count,
    keywords_datasets_tag: state.keywords_datasets_tag,
    cluster_data_filtered: state.cluster_data_filtered,
    selected_datasets: state.selected_datasets,
    final_selected_datasets: state.final_selected_datasets,
    datasets_with_url: state.datasets_with_url,
    no_dataset_returned: state.no_dataset_returned,
    correlated_data: state.correlated_data,
    tsne_data: state.tsne_data,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    set_table_open: (val) => dispatch({ type: "table_open", value: val }),
    set_original_data: (val) => dispatch({ type: "original_data", value: val }),
    set_keywords_datasets_tag: (val) => dispatch({ type: "keywords_datasets_tag", value: val }),
    set_cluster_data_filtered: (val) => dispatch({ type: "cluster_data_filtered", value: val }),
    set_tags_dict: (val) => dispatch({ type: "tags_dict", value: val }),
    set_tags_dict2: (val) => dispatch({ type: "tags_dict2", value: val }),
    set_entropy_results: (val) => dispatch({ type: "entropy_results", value: val }),
    set_risk_scores_total: (val) => dispatch({ type: "risk_scores_total", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(App);
