import React, {Component} from 'react';
import { connect } from "react-redux";
import * as jsonCall from "../../Algorithms/JSONCall";
//import { Card, CardGroup} from 'react-bootstrap';
import WindowedSelect from "react-windowed-select";
import Button from 'react-bootstrap/Button';
import _ from 'lodash';
import * as d3 from "d3";
import 'bootstrap/dist/css/bootstrap.min.css';



var attributes_list1 = ['age', 'gender', 'race'];
//var attributes_list2 = ["age_group", "vic_age_group", "susp_age_group", "perp_age_group", "vict_age","age_1", "age_at_release", "admission_age", "age_class", "subject_age", "patient_age", "age_range", "victim_age","offender_age", "officer_age", "age_at_arrest","sex", "susp_sex", "vic_sex", "perp_sex","sex_1",  "victim_gender", "suspect_gender", "complainant_sex", "officers_sex",  "susp_race", "vic_race", "perp_race", "vict_descent",  "victim_race", "suspect_race", "complainant_race", "officers_race","subject_race", "officer_race"]
var attributes_list2 = ["agegroup", "vicagegroup", "suspagegroup", "perpagegroup", "victage","age1", "ageatrelease", "admissionage", "ageclass", "subjectage", "patientage", "agerange", "victimage","offenderage", "officerage", "ageatarrest","sex", "suspsex", "vicsex", "perpsex","sex1",  "victimgender","offendergender", "suspectgender", "complainantsex", "officerssex",  "susprace", "vicrace", "perprace", "victdescent",  "victimrace","offenderrace", "suspectrace", "complainantrace", "officersrace","subjectrace", "officerrace"]
var options = [];
for (let i = 0; i < attributes_list1.length; i += 1) {
  options.push({
    label: attributes_list1[i],
    value: attributes_list1[i]
  });
}
var selected_list_initial = [...options];
for (let i = 0; i < attributes_list2.length; i += 1) {
  options.push({
    label: attributes_list2[i],
    value: attributes_list2[i]
  });
}

export class  Tags extends Component {
  //const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {selected_list: selected_list_initial};
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleTagsUpdate = this.handleTagsUpdate.bind(this);
  
}
componentDidMount() {
    
   
}
componentDidUpdate() {
   

}
shouldComponentUpdate(nextProps, nextState){
    return true
}

handleTagsUpdate=(dataset_type)=>{
  if((this.props.clicked_tags).length >0){
  var dataset_list = [];
  for (var item of this.props.clicked_tags){
    //console.log(tags_dict2[item[0]]["Dataset"]);
    var temp;
    console.log(this.props.tags_dict2);
    console.log(item.replaceAll(" ",""));
    try{temp = this.props.tags_dict2[item.replaceAll(" ","")]["Dataset"];}
    catch(err){}
    dataset_list.push(...temp)
  }
  var dataset_list_set = new Set(dataset_list)
  dataset_list = [...dataset_list_set]
  console.log(dataset_list);
  this.props.set_dataset_list(dataset_list);
  //this.props.set_isLoadingTags(false);
  //jsonCall.download(this.props.url + "/api/v2/projection1", {attributes_of_interest: this.props.attributes_of_interest}).then(res =>{this.props.set_projection_data(res["message"]);this.props.set_isLoading(false);})
  }//end of if where the clicked tags are not empty
  else{ //if clicked_tags are empty
    if(_.isEqual(dataset_type,["Individual"])){console.log("Individual Updated ",(this.props.dataset_list_individual).length);this.props.set_dataset_list(this.props.dataset_list_individual);}
    else if(_.isEqual(dataset_type,["Aggregated"])){console.log("Aggregated Updated ",(this.props.dataset_list_aggregated).length);this.props.set_dataset_list(this.props.dataset_list_aggregated);}
    else{
      var temp1 = Array.from(this.props.dataset_list_individual);
      temp1.push(...this.props.dataset_list_aggregated);
      console.log("Total Updated ",temp1.length);
      this.props.set_dataset_list(temp1);
    }

  }
} 
  
handleButtonClick=()=>{
    this.props.set_isLoadingUpdate(true);
    if(this.props.selected_portals_temp.length>0){
    console.log(this.props.attributes_of_interest);
    //this.props.set_dataset_type(this.props.dataset_type_temp);
    //this.props.set_plot_type(this.props.plot_type_temp);
    console.log(this.props.plot_type_temp);
    //this.handleTagsUpdate(this.props.dataset_type_temp);
    if(this.props.plot_type_temp === "projection plot"){
    jsonCall.download(this.props.url + "/api/v2/projection2", {attributes_of_interest: this.props.attributes_of_interest_temp, dataset_type:this.props.dataset_type_temp,selected_portals:this.props.selected_portals_temp}).then(res =>{d3.selectAll(".attributes_xaxis").remove();this.props.set_dataset_type(this.props.dataset_type_temp);this.props.set_plot_type(this.props.plot_type_temp);this.handleTagsUpdate(this.props.dataset_type_temp);this.props.set_selected_portals(this.props.selected_portals_temp);this.props.set_sorted_clusters(res["sorted_clusters"]);this.props.set_projection_data(res["message"]); this.props.set_attributes_of_interest(this.props.attributes_of_interest_temp); this.props.set_isLoadingUpdate(false);})}
    else{
      jsonCall.download(this.props.url + "/api/v2/histograms", {dataset_type:this.props.dataset_type_temp,selected_portals:this.props.selected_portals_temp}).then(res =>{d3.selectAll(".attributes_xaxis").remove();this.props.set_dataset_type(this.props.dataset_type_temp);this.props.set_plot_type(this.props.plot_type_temp);this.props.set_selected_portals(this.props.selected_portals_temp);this.handleTagsUpdate(this.props.dataset_type_temp);this.props.set_agg_data(res);this.props.set_isLoadingUpdate(false);})
      
    }
  }//end of bigger if
  else{
    alert("Select at least 1 data portal");
    this.props.set_isLoadingUpdate(false);
  }
    
}  
 



render(){ 
const { selected_list } = this.state;  
var break_character = <div><br /></div>
return (
    <div>
   <Button
      variant="secondary"
      disabled={this.props.isLoadingUpdate}
      onClick={!this.props.isLoadingUpdate ?this.handleButtonClick : null}
      > {this.props.isLoadingUpdate ? 'Loading…' : 'Update'}
    </Button>
  </div>
    
  );
 } //return ends
}

const maptstateToprop = (state) => {
  return {
      url: state.url,
      attributes_of_interest_temp: state.attributes_of_interest_temp,
      isLoadingUpdate: state.isLoadingUpdate,
      attributes_of_interest: state.attributes_of_interest,
      dataset_type_temp: state.dataset_type_temp,
      dataset_type: state.dataset_type,
      plot_type_temp: state.plot_type_temp,
      plot_type:state.plot_type,
      clicked_tags: state.clicked_tags,
      tags_dict2: state.tags_dict2,
      dataset_list_individual: state.dataset_list_individual,
      dataset_list_aggregated: state.dataset_list_aggregated,
      selected_portals:state.selected_portals,
      selected_portals_temp:state.selected_portals_temp,
      
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      set_attributes_of_interest_temp: (val) => dispatch({ type: "attributes_of_interest_temp", value: val }),
      set_isLoadingUpdate: (val) => dispatch({ type: "isLoadingUpdate", value: val }),
      set_attributes_of_interest: (val) => dispatch({ type: "attributes_of_interest", value: val }),
      set_projection_data: (val) => dispatch({ type: "projection_data", value: val }),
      set_dataset_type: (val) => dispatch({ type: "dataset_type", value: val }),
      set_dataset_type_temp: (val) => dispatch({ type: "dataset_type_temp", value: val }),
      set_plot_type_temp: (val) => dispatch({ type: "plot_type_temp", value: val }),
      set_plot_type: (val) => dispatch({ type: "plot_type", value: val }),
      set_agg_data: (val) => dispatch({ type: "agg_data", value: val }),
      set_dataset_list: (val) => dispatch({ type: "dataset_list", value: val }),
      set_sorted_clusters: (val) => dispatch({ type: "sorted_clusters", value: val }),
      set_selected_portals:(val)=>dispatch({type:"set_selected_portals",value:val}), 
      
  }
}

export default connect(maptstateToprop, mapdispatchToprop)(Tags);
