import React, { Component } from 'react';
import * as d3 from 'd3';
import * as d3lasso from 'd3-lasso';
import * as $ from "jquery"
import { connect } from "react-redux";
import * as jsonCall from "../../Algorithms/JSONCall";
import Grid from '@material-ui/core/Grid';
import ProjectionPlot from "./ProjectionPlot";
import ProjectionPlotWordCloud from "./ProjectionPlotWordCloud";
import ProjectionPlotHistogram from "./ProjectionPlotHistogram";
import { Card, CardGroup} from 'react-bootstrap';
import ShowMore from 'react-show-more-button';
import UpdateEndCall from "./UpdateEndCall";
import Button from "@material-ui/core/Button";
import HistogramPlot from "./HistogramPlot";
import projection_result from "./projection_result.json"
//import Toolbar from '@material-ui/core/Toolbar';
//import {makeStyles } from '@material-ui/core/styles';
import "./plotArea.css";

/* const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
})); */

export class Scatterplot extends Component {
  constructor(props) {
    super(props)
    this.state = {
        selected_datasets: {},
        data:{},
        shown:false,
    }
    this.onShowMoreButtonClick = this.onShowMoreButtonClick.bind(this);
  } // end of constructor

  componentDidMount() {
    /* if (Object.entries(this.props.cluster_data_filtered).length > 0) {
        this.create_scatter_plot(Object.entries(this.props.cluster_data_filtered))
    } */
    jsonCall.download(this.props.url + "/api/v2/projection2", {attributes_of_interest: this.props.attributes_of_interest, dataset_type:this.props.dataset_type, selected_portals:this.props.selected_portals})
    .then(
      res =>{if(typeof res=="undefined"){res=projection_result};
        this.props.set_sorted_clusters(res["sorted_clusters"]);this.props.set_projection_data(res["message"]);})
    
  }

  componentDidUpdate() {
    /* if (Object.entries(this.props.cluster_data_filtered).length > 0) {
        this.create_scatter_plot(Object.entries(this.props.cluster_data_filtered))
    } */
    console.log("Number of Datasets ",(this.props.dataset_list).length);
    //console.log(this.state.data);
    //this.create_scatter_plot(this.props.projection_data, this.props.dataset_list);
  }
  shouldComponentUpdate(nextProps, nextState){
    return true
  }

  onShowMoreButtonClick(){
        //this.setState({shown: !this.state.shown});
        d3.select(".showMoreButtonDiv").attr("background", "inherit").attr("opacity", 0.1);
        d3.select(".showMoreButton").attr("background", "inherit").attr("opacity", 1);
  }
  

  render() {
    
    if(Object.keys(this.props.projection_data).length > 0 && this.props.projection_data !== undefined){
    //console.log("I am inside ",this.props.projection_data, Object.keys(this.props.projection_data).length);
    //var clusters = [...new Set(this.props.projection_data.map(d => d.labels))]; //finding the number of clusters
    var clusters = this.props.sorted_clusters;
    console.log("Clusters ",clusters);
    //if($(".dataset_projection_view").length>0){$(".dataset_projection_view").remove();}
    //var clusters=[-1,0,1,2,3,4,5,6];
    return (
      <Card.Body class={"dataset_projection_view"} style={{height: 592, overflowY: "scroll"}}>
        <ShowMore maxHeight={592}  classNameButtonDiv={"showMoreButtonDiv"} classNameButton={"showMoreButton"} styleButtonDiv={{justifyContent:"right", fontSize:"0.875rem", fontFamily:"'Roboto', 'Helvetica', 'Arial', sans-serif", opacity: 0.93 }}  styleButton={{backgroundColor: "white",color:"rgba(0,0,0,0.87)", align:"right", border:"1px solid rgba(0,0,0,0.23)", borderRadius:4,textTransform:"uppercase", cursor:"pointer", width:"20%"}} onChange={this.onShowMoreButtonClick}>{/* button={<Button variant="outlined">Show {(this.state.shown)?"More":"Less"}</Button>} > */}{/* styleButton={{backgroundColor: "white",color:"blue", align:"right", border:0, width:"20%"}}> comment */}
        {
        (clusters.length>0)?clusters.map((item_big,index_big) =>{
          /* d3.select(".showMoreButtonDiv").attr("background", "inherit").attr("opacity", 0.1);
          d3.select(".showMoreButton").attr("background", "inherit").attr("opacity", 1); */
          return <Grid container direction="row" spacing={3}>
            <Grid item xs={7} sm={7}>
              <Grid container direction="row" spacing={0}>
                <Grid item xs={12} sm={12}><ProjectionPlot myid={index_big} cluster={item_big} projection_data={this.props.projection_data} dataset_list={this.props.dataset_list} ></ProjectionPlot></Grid>
                <Grid item xs={12} sm={12}><ProjectionPlotWordCloud myid={"delta_"+index_big} cluster={item_big} projection_data={this.props.projection_data} dataset_list={this.props.dataset_list} original_data={this.props.original_data}></ProjectionPlotWordCloud></Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} sm={5}><ProjectionPlotHistogram myid={index_big} cluster={item_big} attributes_of_interest={this.props.attributes_of_interest} projection_data={this.props.projection_data} dataset_list={this.props.dataset_list} original_data={this.props.original_data}></ProjectionPlotHistogram></Grid>

            
          {/* <Grid item xs={3} sm={3}>
            <Grid container>
            <Grid item xs={3} sm={3}>
            <input
            type="radio"
            onChange={this.handleRadioClick} 
            value={item_big}
            name="radio-button"
            style={{ marginTop:"200%" }}
            ></input>
            </Grid>
            <Grid item xs={9} sm={9}><div style={{ marginTop:"40%" }}>{item_big}</div></Grid>
            </Grid>
            </Grid>
            
                              {[1]!=null?[1].map((item,index) => {
                                //console.log(this.props.agg_data[item_big]);
                                  return <Grid item xs={3} sm={3} id={"grid_"+index_big+"_"+index}>
                                      {this.props.agg_data[item_big]!==(null || undefined)?<HistogramPlot myid={"chart_"+index_big+"_"+index} title={item} item={item} index={index} index_big={index_big} agg_data={this.props.agg_data[item_big][item]} threshold_k={this.props.threshold_k}></HistogramPlot>:null}
                                  </Grid>
                              }):null} */}
             </Grid>
        }
        
        ):<p>No dataset has these combination of attributes</p>} </ShowMore>
        </Card.Body>
        
    )
      }//end of if
      else{return null;}
      
  }// end of render
} //end of class

const maptstateToprop = (state) => {
  return {
      url: state.url,
      dataset_list: state.dataset_list,
      attributes_of_interest: state.attributes_of_interest,
      projection_data: state.projection_data,
      datasets_with_url: state.datasets_with_url,
      keywords_datasets_tag: state.keywords_datasets_tag,
      clicked_tags: state.clicked_tags,
      cluster_data: state.cluster_data,
      cluster_data_filtered: state.cluster_data_filtered,
      dataset_type: state.dataset_type,
      original_data : state.original_data,
      sorted_clusters: state.sorted_clusters,
      selected_portals: state.selected_portals,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      set_selected_datasets: (val) => dispatch({ type: "selected_datasets", value: val }),
      set_final_selected_datasets: (val) => dispatch({ type: "final_selected_datasets", value: val }),
      set_projection_data: (val) => dispatch({ type: "projection_data", value: val }),
      set_sorted_clusters: (val) => dispatch({ type: "sorted_clusters", value: val }),
  }
}

export default connect(maptstateToprop, mapdispatchToprop)(Scatterplot);
