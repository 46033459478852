/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import { connect } from "react-redux";
import * as d3 from 'd3';
import * as $ from "jquery";
import "./matches.css"
import createBins from './CreateBin'
import * as _ from "lodash";

export class MatchesView extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.setState({ random_number: 5, item_height: 75, pHeight: 25 }) //this trick forces react to call componentDidUpdate() even on the first mount
  }
  componentDidUpdate() {
    console.log("hi")
    var data = this.props.matches_data.map(item => item[this.props.attribute])
    if (typeof (data[0]) == "number") {
      this.CreateHistogramNum(data, d3.select("#" + this.props.attribute+this.props.side), $("#" + this.props.attribute+this.props.side).width(), this.state.item_height - this.state.pHeight)
    }
    else {
      var barChartData = _.countBy(data)
      this.create_histogram_cat(barChartData)
    }
  }
  CreateHistogramNum = (item_data, svg, item_width, item_height) => {
    var item_height=$('.e_item_container').height()-17
    const margin = { top: 10, right: 10, bottom: 5, left: 10 }; //margin.left should be 40
    var height = item_height - margin.top - margin.bottom;
    var width = item_width - margin.left - margin.right
    //---------
    var data = []
    var binned_data = createBins(item_data, 20)
    binned_data.map((item, i) => data.push([i, item['count']]))
    //const data = [[0, 0], [1, 80], [2, 20], [3, 210], [4, 130], [5, 270], [6, 30], [7, 110], [8, 130], [9, 0]];
    var yScale = d3.scaleLinear().domain([0, d3.max(data.map(item => item[1]))]).range([height, 0]) // 20 is the baseline
    var xScale = d3.scaleLinear().domain(d3.extent(data.map(item => item[0]))).range([margin.right, width])
    const areaGenerator = d3.area().y0(height).y1(d => yScale(d[1])).x(d => xScale(d[0])).curve(d3.curveBasis)
    svg.append("path").attr('transform', 'translate(0,' + margin.top + ')').attr("d", areaGenerator(data)).style("fill",this.props.attributes_of_interest.includes(this.props.attribute.replace("_left","").replace("_right",""))?"#FF8786":"#BEBEBE");
  }
  create_histogram_cat = (data) => { // data is {key,value} key is the x values and value is the y
    var item_height=$('.e_item_container').height()
    var margin = { top: 15, right: 10, bottom: 7, left: 10 },
      width = $("#" + this.props.attribute+this.props.side).width() - margin.left - margin.right,
      height = item_height - margin.top - margin.bottom;

    var svg = d3.select("#" + this.props.attribute+this.props.side).attr("width", width + margin.left + margin.right).attr("height", height + margin.top + margin.bottom) //----------------------------- Get svg x scale and y scale
    var x = d3.scaleBand().domain(Object.keys(data)).range([0, width]).padding(0.01)
    var y = d3.scaleLinear().domain([0, d3.max(Object.values(data))]).range([height, 0]);

    var xAxis = d3.select("#" + "myXaxis" + "svg0").attr("transform", "translate(" + margin.left + "," + height + ")") //-------------------------------------------------- X axis
    xAxis.call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", "-.55em")
      .attr("transform", "rotate(-90)")
      .selectAll(".tick line").remove()

    var yAxis = d3.select("#" + "myYaxis" + "svg0").attr("class", "myYaxis").attr("transform", "translate(" + margin.left + "," + "0" + ")") //--------------------------------------------------Y axis
    yAxis.call(d3.axisLeft(y).ticks(5));

    var bars = svg.selectAll("rect").data(Object.entries(data), d => d[0]) //------------------------------------------------------------------------------ Drawing starts here
    bars.join(
      enter =>
        enter.append("rect")
          .attr("x", function (d) {
            return x(d[0]) + margin.left
          })
          .attr("y", function (d) { return y(d[1]) })
          .attr("width", x.bandwidth())
          .attr("height", function (d) { return height - y(d[1]) })
          .attr("fill",this.props.attributes_of_interest.includes(this.props.attribute.replace("_left","").replace("_right",""))?"#ff8786":"#BEBEBE"),
      update =>
        update
          .transition()
          .duration(750)
          .attr("x", function (d) {
            return x(d[0]) + margin.left
          })
          .attr("y", function (d) { return y(d[1]) })
          .attr("width", x.bandwidth())
          .attr("height", function (d) { return height - y(d[1]) })
          .attr("fill",this.props.attributes_of_interest.includes(this.props.attribute.replace("_left","").replace("_right",""))?"#ff8786":"#BEBEBE"),
    );
  }

  render() {
    return (
      <div className="e_item_container" style={{ backgroundColor: "rgb(229, 229, 229,0.3)",width:"100%", height: ($('.matches_container_parent').height()-130)/5,border:this.props.attributes_for_match_view.includes(this.props.attribute)?"2px solid grey":"none",marginBottom:10 }} onClick={()=>{
        d3.selectAll(".attributes_xaxis").remove();
        if(this.props.attributes_for_match_view.includes(this.props.attribute)){
          if(this.props.attribute.includes("_left")){
            if(!this.props.attributes_for_match_view.includes(this.props.attribute.replace("_left","_right"))){
              this.props.set_clicked_matched(this.props.clicked_matched.filter(item=>item!=this.props.attribute.replace("_left","").replace("_right","")))
            }
          }
          if(this.props.attribute.includes("_right")){
            if(!this.props.attributes_for_match_view.includes(this.props.attribute.replace("_right","_left"))){
              this.props.set_clicked_matched(this.props.clicked_matched.filter(item=>item!=this.props.attribute.replace("_left","").replace("_right","")))
            }
          }

        } 
        else{this.props.set_clicked_matched([...this.props.clicked_matched,this.props.attribute.replace("_left","").replace("_right","")])}
        
        if(this.props.attributes_for_match_view.includes(this.props.attribute)){
          this.props.set_attributes_for_match_view(this.props.attributes_for_match_view.filter(item=>item!=this.props.attribute))
        }else{this.props.set_attributes_for_match_view([...this.props.attributes_for_match_view,this.props.attribute])}
        
      }}>
      <p style={{ margin: 0, padding: 0,fontSize:12,textAlign:'center' }}>{this.props.attribute.length>14?this.props.attribute.substring(0,14)+"..":this.props.attribute}</p>
        <svg id={this.props.attribute+this.props.side} style={{ width: "100%", height: "100%" }}></svg>
      </div>

    );
  }
}
const maptstateToprop = (state) => {
  return {
    matches_data: state.matches_data,
    mi_result:state.mi_result,
    clicked_matched:state.clicked_matched,
    attributes_of_interest:state.attributes_of_interest,
    attributes_for_match_view: state.attributes_for_match_view,
    selected_datasets_names:state.selected_datasets_names,
    original_data: state.original_data,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    set_table_open: (val) => dispatch({ type: "table_open", value: val }),
    set_clicked_matched: (val) => dispatch({ type: "clicked_matched", value: val }),
    set_attributes_for_match_view: (val) => dispatch({ type: "attributes_for_match_view", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(MatchesView);
