/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Item from "./Item";
import LoadingEndCall from "./LoadingEndCall";
import * as $ from "jquery";
import * as d3 from "d3";
import {sliderBottom}  from 'd3-simple-slider';
import Radio from './Radio';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import * as jsonCall from "../../Algorithms/JSONCall"
import createBins from './CreateBin';
import risk_view_result from "./risk_view_result.json"
class RiskView extends Component {
    constructor(props) {
        super(props)
        this.state = { temp: 1 }
    }
    componentDidMount() {
        this.setState({ temp: 0 })
    }
    componentDidUpdate(prevProps, prevState) {
        
    }
    calculate_combinations(dataset_combinations,selected_datasets){
        let combinations = [];
        if(dataset_combinations.length>0){combinations=dataset_combinations}
        else{
            
            for (let i = 0; i < selected_datasets.length - 1; i++) {
                for (let j = i + 1; j < selected_datasets.length; j++) {
                    combinations.push([selected_datasets[i], selected_datasets[j]]);
                }
            }    
        }
        return combinations;

    }

    calculate_risk_data(combinations,original_data, attributes_of_interest, clicked_matched2,entropy_results,risk_scores_total){
        var risk_view_data = [];
        combinations.map(dataset_pair => {
            var temp1 = {}
            dataset_pair.map(dataset_name => {
                var col = original_data[dataset_name]["Columns Field Name"].filter((columns_name) => !columns_name.startsWith(":@computed"));
                col = col.map(attr =>attr.replaceAll("_",""));
                //console.log("col ",col)
                col.map(attr => {
                    if (temp1[attr] > 0) { temp1[attr] += 1 }
                    else { temp1[attr] = 1 }
                })
            })
            var temp1_arr = Object.entries(temp1).filter(item => item[1] > 1)
            var count = 0
            temp1_arr.map(item => { if (attributes_of_interest.includes(item[0])) { count = count + 1 } })
            //New Risk Score
            var col1 = original_data[dataset_pair[0]]["Columns Field Name"].filter((columns_name) => !columns_name.startsWith(":@computed"));
            var col2 = original_data[dataset_pair[1]]["Columns Field Name"].filter((columns_name) => !columns_name.startsWith(":@computed"));
            col1 = col1.map(attr =>attr.replaceAll("_",""));
            col2 = col2.map(attr =>attr.replaceAll("_",""));
            var common_attributes = _.intersection(col1, col2);
            //Entropy calculation
            var clicked_matched_present = _.intersection(common_attributes,clicked_matched2);
            var temp_entropy_score_0 = 0;
            clicked_matched_present.map(item => {
                temp_entropy_score_0 += entropy_results[dataset_pair[0]][item]
            })
            temp_entropy_score_0 = temp_entropy_score_0/clicked_matched_present.length;

            var temp_entropy_score_1 = 0;
            clicked_matched_present.map(item => {
                temp_entropy_score_1 += entropy_results[dataset_pair[1]][item]
            })
            temp_entropy_score_1 = temp_entropy_score_1/clicked_matched_present.length;
            var final_entropy = (temp_entropy_score_0 + temp_entropy_score_1)/2;
            

            //Entropy bar calculation
            var temp_entropy_dict = {};
            
            common_attributes.map(item =>{
                var entropy_score1 = 0;
                var entropy_score2 = 0;
                try{entropy_score1 = entropy_results[dataset_pair[0].replace("/","")][item];}
                catch(err){entropy_score1 = 0;}
                try{entropy_score2 = entropy_results[dataset_pair[1].replace("/","")][item];}
                catch(err){entropy_score2 = 0;}
                var entropy_score = Math.max(entropy_score1,entropy_score2) //taking the max entropy; it will also set entropy to 0 if value not found
                temp_entropy_dict[item] = entropy_score;
            })
            var entropy_array = this.sort_object(temp_entropy_dict);
            

            var aoi_present = _.intersection(attributes_of_interest,common_attributes);
            var risk_score = 50*aoi_present.length + 1*(common_attributes.length - aoi_present.length);
            risk_view_data.push([dataset_pair, temp1_arr, count, risk_score, final_entropy,entropy_array])
        })
        var sorted_risk_view_data = risk_view_data.sort((a, b) => b[3] - a[3] || b[4] - a[4]);
        var temp_histogram_data = {}
        sorted_risk_view_data.map(item1 => {
            item1[1].map(item2 => {
                if (attributes_of_interest.includes(item2[0])) {
                    if (temp_histogram_data[item2[0]] > 0) { temp_histogram_data[item2[0]] = temp_histogram_data[item2[0]] + 1 }
                    else { temp_histogram_data[item2[0]] = 1 }
                }
            })
        })
        var histogram_data = Object.entries(temp_histogram_data).sort((a, b) => b[1] - a[1])
        var histogram_data_risk_score = sorted_risk_view_data.map(d => d[3]); //this is already sorted; no need to sort again
        var histogram_data_risk_score_binned = createBins(histogram_data_risk_score,8);
        //var risk_score_total_binned = createBins(risk_scores_total,8);
        var risk_score_total_binned = [];

        return[sorted_risk_view_data,histogram_data,histogram_data_risk_score,histogram_data_risk_score_binned,risk_score_total_binned]
    }
    render() {
        var self=this;
        
        var config = { fontSize: 12, max_attr_char: 8, space_for_dataset_name: 130, space_for_risk_score: 80 }
        var attributes_of_interest = this.props.attributes_of_interest;
        var combinations = this.calculate_combinations(this.props.dataset_combinations,this.props.selected_datasets);
        console.log(combinations,'combinations')
        var multi_results = this.calculate_risk_data(combinations, this.props.original_data, this.props.attributes_of_interest, this.props.clicked_matched2, this.props.entropy_results, this.props.risk_scores_total);
        var sorted_risk_view_data = multi_results[0];
        var histogram_data = multi_results[1];
        var histogram_data_risk_score = multi_results[2];
        var histogram_data_risk_score_binned = multi_results[3];
        var risk_score_total_binned = multi_results[4];
        //var entropy_array = multi_results[5];

        this.createHistogram(histogram_data, config)
        this.createHistogramRiskScore(histogram_data,histogram_data_risk_score,histogram_data_risk_score_binned, config,risk_score_total_binned)

        var risk_score_width = config.space_for_risk_score
        var risk_score_ractangle_width = risk_score_width / 5
        
        return (
            <div className="risk_view_container" style={{position:'relative', width: "100%", height: 705, overflowY: "scroll" }}>
                <svg className="histogram_container" style={{ width: "50%", height: 110 }}></svg>
                <svg className="histogram_container_risk_score" style={{ width: "50%", height: 110 }}></svg>
                <hr style={{opacity:0.1}}></hr>
                {/* <Grid container direction="row" spacing={0}>
                    <Grid item xs={1}> </Grid>
                    <Grid item xs={2} style={{fontWeight:650}}>Left</Grid>
                    <Grid item xs={6} style={{fontWeight:650,textAlign:'center'}}>Common Attributes</Grid>
                    <Grid item xs={2} style={{fontWeight:650,textAlign:'center'}}>Right</Grid>
                    <Grid item xs={1} style={{fontWeight:650,textAlign:'start',fontSize:"0.9em"}}>Risk Score</Grid>
                </Grid> */}
                <p style={{ margin: 0, marginLeft: $(".risk_view_container").width() - config.space_for_risk_score*1.2 , fontSize: "0.8em", fontWeight: 500 }}>Joinability Risk</p>
                {
                    sorted_risk_view_data.map((item_data, index) => <div className={"div" + index+" risk_items"} data-risk={item_data[3]} style={{paddingBottom:10}}><Grid container direction="row" spacing={0}><Grid item xs={1}><Radio checked_datasets={this.props.checked_datasets} set_checked_datasets={this.props.set_checked_datasets} item_data={item_data} original_data={this.props.original_data}></Radio></Grid><Grid item xs={11}><Item original_data={this.props.original_data} config={config} div_class={"div" + index} sorted_risk_view_data={sorted_risk_view_data} key={index} class_name={"item" + index} item_data={item_data}  ></Item></Grid></Grid></div>)
                }
                <Button style={{position:'sticky',bottom:0,width:'100%',backgroundColor:'#bcbcbc',padding:0}}
                    variant="secondary"
                    disabled={this.props.isLoadingMatches}
                    onClick={()=>{
                        //!this.props.isLoadingTags ? this.handleTagsButtonClick : null
                        this.props.set_isLoadingMatches(true);
                        var selected_datasets=Object.values(this.props.checked_datasets);
                        var selected_datasets_names = Object.keys(this.props.checked_datasets);
                        var selected_attributes=this.props.clicked_matched.filter(item=>this.props.checked_datasets_clicked_attributes.includes(item))
                        if(selected_datasets.length === 0){
                            alert("Select a pair of datasets from the Privacy Risk View");
                            this.props.set_isLoadingMatches(false);
                            d3.selectAll(".attributes_xaxis").remove()
                        }
                        else if(selected_attributes.length === 0){
                            alert("Select few attributes from the Privacy Risk View");
                            this.props.set_isLoadingMatches(false);
                            d3.selectAll(".attributes_xaxis").remove()
                        }
                        else{//if everything else checks out
                        d3.selectAll(".attributes_xaxis").remove();    
                        var myurl=this.props.url+'/api/v2/matches'
                        jsonCall.download(myurl,{"selected_datasets":selected_datasets,"selected_attributes":selected_attributes})
                        .then(res=>{
                            if(typeof res=='undefined'){res=risk_view_result}
                            self.props.set_mi_result(res['mi_result'],'mi_result');self.props.set_common_records_length(res["results_size"]);self.props.set_selected_datasets_names(selected_datasets_names);self.props.set_dataset_sizes([res["dataset1_size"],res["dataset2_size"]]);self.props.set_dataset_original_sizes([res["dataset1_original_size"],res["dataset2_original_size"]]);self.props.set_flag_both_large_datasets(res["flag_both_large_datasets"]);self.props.set_matches_data(res["message"]);self.props.set_attributes_for_match_view(selected_attributes);d3.selectAll(".attributes_xaxis").remove();this.props.set_isLoadingMatches(false);})
                        }
                    }}
                > {this.props.isLoadingMatches ? 'Loading…' : 'Show Matches'}
                </Button>
                <LoadingEndCall></LoadingEndCall>
            </div>
        );
    }
    createHistogram = (histogram_data, config) => {
        // set the dimensions and margins of the graph
        var margin = { top: 20, right: 30, bottom: 20, left: config.space_for_dataset_name },
            //width = 460 - margin.left - margin.right,
            width = $(".histogram_container").width() - margin.left - margin.right,
            height = $(".histogram_container").height() - margin.top - margin.bottom;

        //Heading
        d3.select(".histogram_container").selectAll('.title_text').data([0]).join('text').attr('class', 'title_text').attr('x', width / 3).attr('y', 2).attr('font-size', "0.8em").attr('font-weight', 600).text('Distribution of Privacy-related Attributes').attr('dominant-baseline', 'hanging');

        if (histogram_data.length == 0) { d3.select(".histogram_container").selectAll('.empty_text').data([0]).join('text').attr('class', 'empty_text').attr('x', width / 6).attr('y', height / 2).attr('font-size', 20).attr('font-weight', 600).text('No attributes of interest found in this view').attr('dominant-baseline', 'hanging') } else { d3.selectAll('.empty_text').remove() }

        // append the svg object to the body of the page
        var svg = d3.select(".histogram_container").selectAll(".parent_g").data([0]).join("g").attr("class", "parent_g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // Add X axis
        var x = d3.scaleLinear().domain([0, d3.max(histogram_data.map(item => item[1]))]).range([0, width])

        // Y axis
        var y = d3.scaleBand().range([0, height]).domain(histogram_data.map(item => item[0])).padding(.03);
        svg.selectAll(".left_axis_g").data([0]).join("g").attr("class", "left_axis_g").call(d3.axisLeft(y)).selectAll("text").attr("font-size", 16).style("fill", "#F78787").style("font-weight",550)

        //Bars
        svg.selectAll(".myRect").data(histogram_data).join("rect").attr("class", "myRect").attr("x", 0)
            .attr("y", function (d) { return y(d[0]); }).attr("width", function (d) { return x(d[1]); }).attr("height", y.bandwidth()).attr("fill", "rgb(190,190,190)")
        svg.selectAll(".domain").remove()

        svg.selectAll(".myText").data(histogram_data).join("text").attr("x", d => 3 + x(d[1])).attr("class", "myText").attr('dominant-baseline',"middle")
            .attr("y", d => y(d[0]) + y.bandwidth() / 2).text(d => d[1])
    }
    createHistogramRiskScore = (histogram_data, histogram_data_risk_score,histogram_data_risk_score_binned, config,risk_score_total_binned) => {
        // set the dimensions and margins of the graph
        
        var margin = { top: 20, right: 30, bottom: 20, left: 30 },
            //width = 460 - margin.left - margin.right,
            width = $(".histogram_container_risk_score").width() - margin.left - margin.right,
            height = $(".histogram_container_risk_score").height() - margin.top - margin.bottom;

        //if (histogram_data.length == 0) { d3.select(".histogram_container").selectAll('.empty_text').data([0]).join('text').attr('class', 'empty_text').attr('x', width / 2).attr('y', height / 2).attr('font-size', 20).attr('font-weight', 600).text('No attributes of interest found in this view').attr('dominant-baseline', 'hanging') } else { d3.selectAll('.empty_text').remove() }
        //Heading
        d3.select(".histogram_container_risk_score").selectAll('.title_text2').data([0]).join('text').attr('class', 'title_text2').attr('x', width / 3).attr('y', 2).attr('font-size', "0.8em").attr('font-weight', 600).text('Risk Score Distribution').attr('dominant-baseline', 'hanging');
        // append the svg object to the body of the page
        var svg = d3.select(".histogram_container_risk_score").selectAll(".parent_g").data([0]).join("g").attr("class", "parent_g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        // Calculations
        var x_limits = []; //will contain the X axis domains
        var all_x0 = histogram_data_risk_score_binned.map(d => d["x0"]);
        var all_x1 = histogram_data_risk_score_binned.map(d => d["x1"]);
        x_limits[0] = d3.min(all_x0);
        x_limits[1] = d3.max(all_x1);
        var myTicks = [];
        myTicks.push(...all_x0);
        myTicks.push(...all_x1);
        var myTicks_ = [...new Set(myTicks)]; //unique ticks
        

        

        // Add X axis
        var x = d3.scaleLinear().domain(x_limits).range([0, width])

        //Slider Calculation
        /* var sliderRange = sliderBottom().min(x_limits[1]).max(x_limits[0]).width(width).ticks(0).default([x_limits[0], x_limits[1]]).fill('rgb(255,255,255')
                            .on('onchange', val => {
                            //d3.select('p#value-range').text(val.map(d3.format('.2%')).join('-'));
                            var myItems = $(".risk_items").filter(function() {
                                    var risk_score1 = parseInt($(this).attr("data-risk"));
                                    return risk_score1 >= val[0] && risk_score1 <= val[1];
                                });
                            console.log(val);
                            //console.log(myItems);
                            $(".risk_items").hide();
                            myItems.show();
                            }); */
          
        var sliderRange = []                 
        sliderRange = new sliderBottom(x)
        sliderRange.ticks(0).default([x_limits[0], x_limits[1]]).fill('rgb(255,255,255)')
                            .on('onchange', val => {
                            //d3.select('p#value-range').text(val.map(d3.format('.2%')).join('-'));
                            var myItems = $(".risk_items").filter(function() {
                                    var risk_score1 = parseInt($(this).attr("data-risk"));
                                    return risk_score1 >= val[0] && risk_score1 <= val[1];
                                });
                            
                            //console.log(myItems);
                            $(".risk_items").hide();
                            myItems.show();
                            });  
        
        //Call X axis                    
        svg.selectAll(".bottom_axis_g").data([0]).join("g").attr("class", "bottom_axis_g").attr("transform", "translate(0,"+height+")").call(d3.axisBottom(x).tickValues(myTicks_)).selectAll("text").attr("font-size", 12);
        // Y axis
        var y_temp = d3.extent(histogram_data_risk_score_binned.map(item => item["count"]));
        y_temp[0] = -0.2;
        var y = d3.scaleLinear().range([height, 0]).domain(y_temp);
        svg.selectAll(".left_axis_g").data([0]).join("g").attr("class", "left_axis_g").call(d3.axisLeft(y).ticks(4).tickFormat(d3.format(".2s"))).selectAll("text").attr("font-size", 12)

        var grad = svg.append("defs")
                    .append("linearGradient")
                    .attr("id", "grad");
        //grad.append("stop").attr("offset", "0%").attr("stop-color", "rgb(189,22,40)").attr("stop-opacity", 1);
        //grad.append("stop").attr("offset", "100%").attr("stop-color", "rgb(246,60,65)").attr("stop-opacity", 1);
        //grad.append("stop").attr("offset", "0%").attr("stop-color", "rgb(69,202,0)").attr("stop-opacity", 0.9); //"#66bd63"
        //grad.append("stop").attr("offset", "100%").attr("stop-color", "rgb(245,38,1)").attr("stop-opacity", 0.9); //"#f46d43"
        grad.append("stop").attr("offset", "0%").attr("stop-color", "rgb(190,190,190)").attr("stop-opacity", 0.9); //"#66bd63"
        grad.append("stop").attr("offset", "100%").attr("stop-color", "rgb(245,38,1)").attr("stop-opacity", 0.9); //"#f46d43"
        //Gradient: https://stackoverflow.com/questions/19114896/d3-js-chart-area-filling-with-different-colors
        // Different red color scheme:https://www.schemecolor.com/fall-red-color-scheme.php

        //Area
        var histogram_data_risk_score_binned_ =[]; //custom bin to keep only the midpoints of the bins along with counts of 0 for the start and end points
        histogram_data_risk_score_binned.map(item => {histogram_data_risk_score_binned_.push([item['x0'],0]);histogram_data_risk_score_binned_.push([parseInt((item['x0']+item['x1'])/2),item['count']]); histogram_data_risk_score_binned_.push([item['x1'],0]); }) //getting the midpoints of the bins
        svg.selectAll(".myPath").data([0]).join("path").attr("class", "myPath").attr("fill","rgb(190,190,190)").attr("stroke","rgb(190,190,190)")/* .style("fill", "url(#grad)") */.attr("d",
        function(d){
            //console.log(x(d[0]),x(d[1]),y(d[2]));
            return d3.area()
            .x(d => x(d[0]))
            //.x1(d => x(d[1]))
            .y0(y(0))
            .y1(d => y(d[1]))
            .curve(d3.curveMonotoneX)
            (histogram_data_risk_score_binned_)
        })
        d3.selectAll('.bottom_axis_g1').remove()
        svg.selectAll(".bottom_axis_g1").data([0]).join("g").attr("class", "bottom_axis_g1").attr("transform", "translate(0,"+height+")").call(sliderRange);

        /* var histogram_data_risk_score_binned_ =[]; //custom bin to keep only the midpoints of the bins along with counts of 0 for the start and end points
        histogram_data_risk_score_binned.map(item => {histogram_data_risk_score_binned_.push([item['x0'],0]);histogram_data_risk_score_binned_.push([parseInt((item['x0']+item['x1'])/2),item['count']]); histogram_data_risk_score_binned_.push([item['x1'],0]); }) //getting the midpoints of the bins
        svg.selectAll(".myPath2").data([0]).join("path").attr("class", "myPath2").attr("fill","rgb(190,190,190)").attr("stroke","rgb(190,190,190)").attr("d",
        function(d){
            //console.log(x(d[0]),x(d[1]),y(d[2]));
            return d3.area()
            .x(d => x(d[0]))
            //.x1(d => x(d[1]))
            .y0(y(0))
            .y1(d => y(d[1]))
            .curve(d3.curveMonotoneX)
            (histogram_data_risk_score_binned_)
        }) */
        
        //Bars
        /* svg.selectAll(".myRect").data(histogram_data).join("rect").attr("class", "myRect").attr("x", 0)
            .attr("y", function (d) { return y(d[0]); }).attr("width", function (d) { return x(d[1]); }).attr("height", y.bandwidth()).attr("fill", "rgb(190,190,190)") */
        /* svg.selectAll(".domain").remove() */

        /* svg.selectAll(".myText").data(histogram_data).join("text").attr("x", d => 3 + x(d[1])).attr("class", "myText").attr('dominant-baseline',"middle")
            .attr("y", d => y(d[0]) + y.bandwidth() / 2).text(d => d[1]) */
    }

    sort_object = (dict) => {
        var items = Object.keys(dict).map(function (key) {
          return [key, dict[key]];
        });
        items.sort(function (first, second) {
          return second[1] - first[1];
        });
        return items;
      }
};
const maptstateToprop = (state) => {
    return {
        dataset_combinations:state.dataset_combinations,
        original_data: state.original_data,
        selected_datasets: state.selected_datasets,
        attributes_of_interest:state.attributes_of_interest,
        matches_data: state.matches_data,
        clicked_matched:state.clicked_matched,
        clicked_unmatched:state.clicked_unmatched,
        clicked_matched2:state.clicked_matched2,
        checked_datasets:state.checked_datasets,
        checked_datasets_clicked_attributes:state.checked_datasets_clicked_attributes,
        isLoadingMatches: state.isLoadingMatches,
        url:state.url,
        attributes_for_match_view:state.attributes_for_match_view,
        selected_datasets_names:state.selected_datasets_names,
        entropy_results: state.entropy_results,
        risk_scores_total: state.risk_scores_total,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        set_mi_result: (val) => dispatch({ type: "mi_result", value: val }),
        set_checked_datasets: (val) => dispatch({ type: "checked_datasets", value: val }),
        set_clicked_matched: (val) => dispatch({ type: "clicked_matched", value: val }),
        set_clicked_unmatched: (val) => dispatch({ type: "clicked_unmatched", value: val }),
        set_matches_data: (val) => dispatch({ type: "matches_data", value: val }),
        set_attributes_for_match_view: (val) => dispatch({ type: "attributes_for_match_view", value: val }),
        set_isLoadingMatches: (val) => dispatch({ type: "isLoadingMatches", value: val }),
        set_common_records_length: (val) => dispatch({ type: "common_records_length", value: val }),
        set_selected_datasets_names: (val) => dispatch({ type: "selected_datasets_names", value: val }),
        set_dataset_sizes: (val) => dispatch({ type: "dataset_sizes", value: val }),
        set_dataset_original_sizes: (val) => dispatch({ type: "dataset_original_sizes", value: val }),
        set_flag_both_large_datasets: (val) => dispatch({ type: "flag_both_large_datasets", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(RiskView);