/* eslint-disable no-loop-func */
import React, { Component } from 'react';
import { connect } from "react-redux";
import * as d3 from 'd3';
import * as $ from "jquery";
import * as _ from "lodash";
import "./matches.css"
import Grid from '@material-ui/core/Grid';
import { Card, CardGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import resultX33_34_test_top1000 from "../../data/resultX33_34_test_top1000.csv" //"./data/resultX33_34_test_top100.csv";
import CreateHistogramNum from "./CreateHistogram"
import { CreateHistogramCat } from "./CreateHistogram"
import Dialogs from './Dialogue';
import ExtraHistograms from './ExtraHistograms';
export class MatchesView extends Component {
  //const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = { selected_list: [], dialogue_data: { open: false, item: null }, random_number: 10, clicked_attrs: [] };
    this.createMatchesView = this.createMatchesView.bind(this);
  }
  componentDidMount() {
    this.setState({ random_number: 5 }) //this trick forces react to call componentDidUpdate() even on the first mount
  }
  componentDidUpdate() {
    if (this.props.common_records_length === 0) {
      $('.matches_container').hide();
      $('.matches_container').empty();
    }
    else if (this.props.common_records_length > 4000) {
      if (this.props.matches_data.length > 0) {
        $('.matches_container').empty();
        this.createMatchesView(this.props.matches_data);
        $('.matches_container').show();
      }
    }
    else {
      //this.props.set_common_records_length(this.props.matches_data.length);
      if (this.props.matches_data.length > 0) {
        $('.matches_container').empty();
        this.createMatchesView(this.props.matches_data);
        $('.matches_container').show();
      }
    }
  }
  update_clicked_attrs = (arr) => {
    this.setState({ clicked_attrs: arr })
  }
  createMatchesView(matches_view_data) {
    var self = this
    var div = d3.select("body").selectAll(".tooltip").data([0]).join('div').attr("class", "tooltip").style("opacity", 0);
    var line_data = {}
    var parent_width = $(".matches_container_parent").width()
    var parent_height = $(".matches_container").height()

    var item_width = parent_width / this.props.attributes_for_match_view.length
    var item_height = parent_height
    var number_of_attributes = (this.props.attributes_for_match_view).length;

    d3.select('.matches_container').attr('width', parent_width).selectAll(".items").data(this.props.attributes_for_match_view).join('g').attr('class', "items").attr("transform", (d, i) => "translate(" + i * item_width + ",0)")
      .attr('add_title', function (d) {
        d3.select(this).selectAll('.title_text').data([0]).join('text').attr('class', 'title_text').text(d).attr('dominant-baseline', 'hanging').attr('x', item_width / 4).attr('text-anchor', "middle").attr('font-size', 18).attr('font-weight', 500)
      })
      .attr('CreatedHistogram', function (d, item_index) {
        var item = d.toLowerCase();
        if (!isNaN(matches_view_data[0][item]) && matches_view_data.length >= 20) { CreateHistogramNum(matches_view_data, d3.select(this), item, item_width, item_height, line_data, item_index, self.props.attributes_of_interest) }
        else { CreateHistogramCat(matches_view_data, d3.select(this), item, item_width, item_height, line_data, item_index, number_of_attributes, self.props.attributes_of_interest) }
      })

    var lines = []
    var line_data_arr = Object.entries(line_data);
    var is_length_small_flag = matches_view_data.length < 20;
    if (line_data_arr.length > 1) {
      for (var i = 0; i < line_data_arr.length - 1; i++) {
        line_data_arr[i][1].map(attribute_1_bins => { // line_data_arr[i] has [attribute,[lower,upper]] attribute_1_bins has the category or the bins with [lower,upper]
          var attr1 = line_data_arr[i][0]
          line_data_arr[i + 1][1].map(attribute_2_bins => {
            var stroke_data = []
            var attr2 = line_data_arr[i + 1][0]
            matches_view_data.map(item => {
              if (is_length_small_flag) { //if the number of records are low, everything is considered categorical
                if (item[attr1] == attribute_1_bins[1] && item[attr2] == attribute_2_bins[1]) {
                  stroke_data.push(item)

                }
              }
              else {
                if (isNaN(attribute_1_bins[1])) { // if attr1 categorical

                  //if(attribute_1_bins[1] !== "..."){
                  if (isNaN(attribute_2_bins[1])) { // if attr2 categorical
                    if (item[attr1] == attribute_1_bins[1] && item[attr2] == attribute_2_bins[1]) {
                      stroke_data.push(item)

                    }
                  }
                  else { // if attr2 numerical and attr1 is categorical
                    if (parseInt(item[attr2]) > attribute_2_bins[0] && parseInt(item[attr2]) <= attribute_2_bins[1] && item[attr1] == attribute_1_bins[1]) {
                      stroke_data.push(item)
                    }
                  }
                } //end of handling of ... in 1st variable
                //}
                else { // if attr1 numerical
                  if (isNaN(attribute_2_bins[1])) { // if attr2 Categorical
                    if (parseInt(item[attr1]) > attribute_1_bins[0] && parseInt(item[attr1]) <= attribute_1_bins[1] && item[attr2] == attribute_2_bins[1]) {
                      stroke_data.push(item)
                    }
                  }
                  else { // both numerical
                    if (parseInt(item[attr1]) > attribute_1_bins[0] && parseInt(item[attr1]) <= attribute_1_bins[1] && parseInt(item[attr2]) > attribute_2_bins[0] && parseInt(item[attr2]) <= attribute_2_bins[1]) {
                      stroke_data.push(item)
                    }
                  }
                }
              } //end of bigger else
            })
            //var item1_id = '#id_' + (attribute_1_bins[1] !== "...")?attribute_1_bins[1]:"othersdotdotdot"; //"othersdotdotdot"
            var item1_id = "#" + attr1 + 'id_' + attribute_1_bins[1];
            if (item1_id === "#" + attr1 + "id_...") { item1_id = "#" + attr1 + 'id_' + "othersdotdotdot" }
            var item2_id = "#" + attr2 + 'id_' + attribute_2_bins[1] // attribute_2_bins has the category or the bins with [lower,upper] - attribute_1_bins and attribute_2_bins has similar structure
            if (item2_id === "#" + attr2 + "id_...") { item2_id = "#" + attr2 + 'id_' + "othersdotdotdot" }
            lines.push([item1_id.replace(/ /g, '').replace(/[&\/\\,+()$~%.'":*?<>{}]/g, ''), item2_id.replace(/ /g, '').replace(/[&\/\\,+()$~%.'":*?<>{}]/g, ''), stroke_data, { 'origin': attr1, 'destination': attr2 }])
          })
        })
      }
      lines.sort((a, b) => b[2].length - a[2].length) // sort the lines data
      var stroke_scale = d3.scaleLinear().domain([lines[lines.length - 1][2].length, lines[0][2].length]).range([3, 40])

      //-----------------------------
      d3.select('.matches_container').selectAll('.mylines').data(lines, (d, i) => d).join('line').attr('class', 'mylines').attr("stroke-width", d => stroke_scale(d[2].length)).attr("count", d => d[2].length).attr("stroke", "rgb(40, 40, 40,0.5)").lower()
        .attr("x1", function (d) {
          return parseInt(d3.select(d[0]).attr('x2Position')) - 5
        })
        .attr("x2", function (d) {
          return parseInt(d3.select(d[1]).attr('x1Position')) + 5
        }).attr("y1", function (d) {
          return d3.select(d[0]).attr('yPosition')
        }).attr("y2", function (d) {
          return d3.select(d[1]).attr('yPosition')
        })

        .on('contextmenu', function (d) {
          d3.event.preventDefault();
          self.props.set_table_data(d[2])
          self.setState({ dialogue_data: { open: true, item: "Hi", x: d3.event.pageX - 30, y: d3.event.pageY - (100 + window.pageYOffset) } })
        })
        .attr("remove_zero_length", function (d) {
          if (d[2].length == 0) { d3.select(this.remove()) }
        })
        .on('mouseover', function (d) {
          d3.selectAll('.mylines').attr('opacity', 0.3)
          div.transition().duration(200).style("opacity", .9);
          div.html("Items: " + d[2].length).style("left", (d3.event.pageX) + "px").style("top", (d3.event.pageY + 20) + "px")
          var data = d3.select(this).data()[0]
          if (typeof (d3.select(d[0]).data()[0].length) == "undefined") { // For numeric attribute
            var upper_range = parseInt(d3.select(d[0]).attr('id_value'))
            var lower_range = null
            var attr2_value = d3.select(data[1]).attr('id_value') // like male
            var attr1 = data[3]['origin'] // like age
            var attr2 = data[3]['destination'] // like gender
            line_data[attr1].map(item => { if (item[1] == upper_range) { lower_range = item[0] } })
            d3.selectAll('.mylines').attr('add_highlight', function () {
              d3.select(this).data()[0][2].map(item => {
                if (parseInt(item[attr1]) > lower_range && parseInt(item[attr1]) <= upper_range && item[attr2] == attr2_value) { d3.select(this).classed('line_hoverd', true) }
              })
            })
          }
          else { // For categorical attribute
            var attr1_value = d3.select(data[0]).attr('id_value')
            var attr2_value = d3.select(data[1]).attr('id_value')
            var attr1 = data[3]['origin']
            var attr2 = data[3]['destination']
            d3.selectAll('.mylines').attr('add_highlight', function () {
              d3.select(this).data()[0][2].map(item => {
                if (item[attr1] == attr1_value && item[attr2] == attr2_value) { d3.select(this).classed('line_hoverd', true) }
              })
            })
          }
        })
        .on('mouseout', () => { d3.selectAll('.mylines').classed('line_hoverd', false); div.transition().duration(200).style("opacity", 0); d3.selectAll('.mylines').attr('opacity', 1) })
      //-----------------------------
    }//end of normal scenario
    else { //when only 1 attribute is selected
      var stroke_data = {};
      var stroke_data_smaller = [];
      var attribute_categories = 0; //counting this to limit the number of categories in the categorical attribute
      line_data_arr[0][1].map(attribute_1_bins => { // line_data_arr[i] has [attribute,[lower,upper]] attribute_1_bins has the category or the bins with [lower,upper]
        if (isNaN(attribute_1_bins[1]) && attribute_categories < 20) {
          var attr1 = line_data_arr[0][0];
          var stroke_data_small = [];
          matches_view_data.map(item => {
            if (item[attr1] == attribute_1_bins[1]) {
              stroke_data_small.push(item);
            }
          })
          var item1_id = '#id_' + attribute_1_bins[1];
          item1_id = item1_id.replace(/ /g, '').replace(/[&\/\\,+()$~%.'":*?<>{}]/g, '')
          stroke_data[item1_id] = stroke_data_small;
          d3.select(item1_id)
            .on('contextmenu', function (d) {
              d3.event.preventDefault();

              self.props.set_table_data(stroke_data_small)
              self.setState({ dialogue_data: { open: true, item: "Hi", x: d3.event.pageX - 30, y: d3.event.pageY - (100 + window.pageYOffset) } })
            })
          attribute_categories += 1;
        }//end of categorical and less than 20
        else if (isNaN(attribute_1_bins[1]) && attribute_categories >= 20) {
          var attr1 = line_data_arr[0][0];
          var stroke_data_small = [];
          matches_view_data.map(item => {
            if (item[attr1] == attribute_1_bins[1]) {
              stroke_data_smaller.push(item);
            }
          })

          attribute_categories += 1;
        } //end of categorical and greater than 20


      })
      if (attribute_categories >= 20) {
        var item1_id = '#id_' + "othersdotdotdot";
        item1_id = item1_id.replace(/ /g, '').replace(/[&\/\\,+()$~%.'":*?<>{}]/g, '')

        d3.select(item1_id)
          .on('contextmenu', function (d) {
            d3.event.preventDefault();
            d3.selectAll(".tooltip_categories").style("opacity", 0);
            self.props.set_table_data(stroke_data_smaller)
            self.setState({ dialogue_data: { open: true, item: "Hi", x: d3.event.pageX - 30, y: d3.event.pageY - (100 + window.pageYOffset) } })
          })
      }
    }// when there is only 1 attribute
  }
  set_dialogue_data = (dialogue_data) => { this.setState({ dialogue_data: dialogue_data }) }
  render() {
    var selected_datasets = Object.values(this.props.checked_datasets);
    var text_to_be_printed = (parseInt(this.props.flag_both_large_datasets) == 1) ? "Dataset has been clipped to the first 10,000 records" : "Only the first 100,000 records have been fetched";
    var tooltip = d3.select("body").append("div")
      .attr("class", "tooltip_matches")
      .style("opacity", 0);
    d3.selectAll(".matches_question_mark").on("mouseover", function (d) {
      tooltip.transition()
        .duration(200)
        .style("opacity", .9);
      tooltip.html(text_to_be_printed)
        .style("left", (d3.event.pageX + 5) + "px")
        .style("top", (d3.event.pageY - 28) + "px");
    })
      .on("mouseout", function (d) {
        tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      })
    var a = this.props.mi_result.sort((a, b) => b[1] - a[1]).map(item => item[0][1])
    var attrs_array = a.filter(function (item, pos) { return a.indexOf(item) == pos })
    var temp_left_dataset_attrs = this.props.original_data[this.props.selected_datasets_names[0]]['Columns Field Name'].filter((columns_name) => !columns_name.startsWith(":@computed")).map(attr => attr.replaceAll("_", ""))
    var left_dataset_attrs = attrs_array.filter(item => temp_left_dataset_attrs.includes(item) || item.includes("_left"));
    var temp_right_dataset_attrs = this.props.original_data[this.props.selected_datasets_names[1]]['Columns Field Name'].filter((columns_name) => !columns_name.startsWith(":@computed")).map(attr => attr.replaceAll("_", ""));
    var right_dataset_attrs = attrs_array.filter(item => temp_right_dataset_attrs.includes(item) || item.includes("_right"));
    return (
      <CardGroup>
        <Card>
          <Card.Header>Evaluate Privacy Risks</Card.Header>
          <Card.Body>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <Card.Body>
                    <Grid container>
                      <Grid item xs={12}>Number of valid records in Dataset <a href={selected_datasets[0]} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "inherit" }}><i>{(this.props.selected_datasets_names)[0]}</i></a>: {(this.props.dataset_sizes)[0]} <sup class="matches_question_mark">{((this.props.dataset_original_sizes)[0] >= 100000) ? <FontAwesomeIcon icon={faQuestionCircle} /> : null}</sup> </Grid>
                      <Grid item xs={12}>Number of valid records in Dataset <a href={selected_datasets[1]} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "inherit" }}><i>{(this.props.selected_datasets_names)[1]}</i></a>: {(this.props.dataset_sizes)[1]} <sup class="matches_question_mark">{((this.props.dataset_original_sizes)[1] >= 100000) ? <FontAwesomeIcon icon={faQuestionCircle} /> : null}</sup></Grid> <br></br> <br></br>
                      <Grid item xs={12} class={"summary_statement"}>{(this.props.common_records_length === 0) ? "Joining the datasets on these attributes yields no common record" : (this.props.common_records_length > 4000) ? "Joining the datasets on these attributes yields " + this.props.common_records_length + " common records. But only the first 4000 records are being shown:" : "Joining the datasets on these attributes yields " + this.props.common_records_length + " common records:"}</Grid>
                    </Grid>
                  </Card.Body>
                </Card>
              </Grid>
              {this.props.matches_data.length > 0?<Grid container item xs={12}>
                <Grid item container direction="column" justifyContent="center" alignItems="center" style={{ width: 110, borderRight: "1px solid rgb(140, 140, 140,0.5)" }}><p className="s_title" style={{ margin: 0, padding: 0, marginBottom: 10, fontWeight: 600 }}>{this.props.selected_datasets_names[0]}</p>
                  {left_dataset_attrs.slice(0, 5).map(item => <Grid item><ExtraHistograms side="left" attribute={item} clicked_attrs={this.state.clicked_attrs} set_clicked_attrs={(arr) => this.setState({ clicked_attrs: arr })}></ExtraHistograms></Grid>)}
                </Grid>
                <Grid item xs className="matches_container_parent" style={{ overflow: "scroll", backgroundColor: "white", height: "560px" }}>
                  <svg className="matches_container" style={{ height: '560', overflowY: "scroll", padding: 5 }}></svg>
                  <Dialogs set_table_open={this.props.set_table_open} set_dialogue_data={this.set_dialogue_data} dialogue_data={this.state.dialogue_data}></Dialogs>
                </Grid>
                <Grid item container direction="column" justifyContent="center" alignItems="center" style={{ width: 110, borderLeft: "1px solid rgb(140, 140, 140,0.5)" }}><p className="s_title" style={{ margin: 0, padding: 0, marginBottom: 10, fontWeight: 600 }}>{this.props.selected_datasets_names[1]}</p>
                  {right_dataset_attrs.slice(0, 5).map(item => <Grid item><ExtraHistograms side='right' attribute={item} clicked_attrs={this.state.clicked_attrs} set_clicked_attrs={(arr) => this.setState({ clicked_attrs: arr })}></ExtraHistograms></Grid>)}
                </Grid>
              </Grid>:null}
            </Grid>
          </Card.Body>
        </Card>
      </CardGroup>
    );
  }
}
const maptstateToprop = (state) => {
  return {
    url: state.url,
    attributes_of_interest: state.attributes_of_interest,
    tags_dict2: state.tags_dict2,
    datasets_with_url: state.datasets_with_url,
    isLoading: state.isLoading,
    isLoadingTags: state.isLoadingTags,
    keywords_datasets_tag: state.keywords_datasets_tag,
    clicked_tags: state.clicked_tags,
    cluster_data: state.cluster_data,
    cluster_data_filtered: state.cluster_data_filtered,
    matches_data: state.matches_data,
    attributes_for_match_view: state.attributes_for_match_view,
    common_records_length: state.common_records_length,
    dataset_sizes: state.dataset_sizes,
    checked_datasets: state.checked_datasets,
    dataset_original_sizes: state.dataset_original_sizes,
    flag_both_large_datasets: state.flag_both_large_datasets,
    mi_result: state.mi_result,
    selected_datasets_names: state.selected_datasets_names,
    original_data: state.original_data,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
    set_selected_datasets: (val) => dispatch({ type: "selected_datasets", value: val }),
    set_final_selected_datasets: (val) => dispatch({ type: "final_selected_datasets", value: val }),
    set_attributes_of_interest: (val) => dispatch({ type: "attributes_of_interest", value: val }),
    set_projection_data: (val) => dispatch({ type: "projection_data", value: val }),
    set_isLoading: (val) => dispatch({ type: "isLoading", value: val }),
    set_isLoadingTags: (val) => dispatch({ type: "isLoadingTags", value: val }),
    set_dataset_list: (val) => dispatch({ type: "dataset_list", value: val }),
    set_matches_data: (val) => dispatch({ type: "matches_data", value: val }),
    set_common_records_length: (val) => dispatch({ type: "common_records_length", value: val }),
    set_table_data: (val) => dispatch({ type: "table_data", value: val }),
    set_table_open: (val) => dispatch({ type: "table_open", value: val }),
  }
}
export default connect(maptstateToprop, mapdispatchToprop)(MatchesView);
