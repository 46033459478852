import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import portals_json from './portals_json.json';
import {connect} from "react-redux";

function CheckboxLabels(props) {
  console.log(props.selected_portals_temp);
  /* var all_portals = [];
  Object.entries(portals_json).map(item => all_portals.push(item[1])); */
  const handleChange = (event) => {
    if(props.selected_portals_temp.includes(event.target.name))
    {
      var temp=props.selected_portals_temp.filter(item=>item !== event.target.name)
      props.set_selected_portals_temp(temp)
    }  
    else{
      props.set_selected_portals_temp([...props.selected_portals_temp,event.target.name])
    }
  };

  const selectAll = (event) =>{
    props.set_selected_portals_temp(props.all_selected_portals);
  }
  const deselectAll = (event) =>{
    props.set_selected_portals_temp([]);
  }
  return (
    <div>
    <FormGroup row>
      <FormControlLabel 
        control={<Checkbox  checked={_.isEqual(props.selected_portals_temp,props.all_selected_portals)} onChange={selectAll} name={"selectAll"}  />}
        label={"Select All"}
        key={"selectAll"}
        style={{fontStyle:"italic"}}
      />
      <FormControlLabel 
        control={<Checkbox checked={_.isEqual(props.selected_portals_temp,[])} onChange={deselectAll} name={"deselectAll"}  />}
        label={"Deselect All"}
        key={"deselectAll"}
        style={{fontStyle:"italic"}}
      />
    </FormGroup>
    <FormGroup row>
      {Object.entries(portals_json).map(item=>{
      return <FormControlLabel 
        control={<Checkbox checked={props.selected_portals_temp.includes(item[1])} onChange={handleChange} name={item[1]}  />}
        label={item[0]}
        key={item[0]}
      />})}
    </FormGroup>
    </div>
  );
}
const maptstateToprop=(state)=>{
    return{
      selected_portals_temp:state.selected_portals_temp,
      all_selected_portals:state.all_selected_portals,
    }
  }
  const mapdispatchToprop=(dispatch)=>{
    return{
      set_selected_portals_temp:(val)=>dispatch({type:"set_selected_portals_temp",value:val}),  
    }
  }
export default connect(maptstateToprop,mapdispatchToprop)(CheckboxLabels);
  
