import React, { Component } from 'react';
import * as d3 from 'd3';
import * as d3lasso from 'd3-lasso';
import { connect } from "react-redux";
import * as $ from "jquery";
import * as jsonCall from "../../Algorithms/JSONCall";
import Grid from '@material-ui/core/Grid';
import HistogramPlot from "./HistogramPlot";
//import Toolbar from '@material-ui/core/Toolbar';
//import {makeStyles } from '@material-ui/core/styles';
import "./plotArea.css";

/* const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
})); */

export  class Scatterplot extends Component {
  constructor(props) {
    super(props)
    this.state = {
        selected_datasets: {},
        data:{},
    }
  } // end of constructor

  componentDidMount() {
    /* if (Object.entries(this.props.cluster_data_filtered).length > 0) {
        this.create_scatter_plot(Object.entries(this.props.cluster_data_filtered))
    } */
    console.log("Now mounted");
    this.create_scatter_plot(this.props.projection_data, this.props.dataset_list, this.props.myid, this.props.cluster);
    //jsonCall.download(this.props.url + "/api/v2/projection2", {attributes_of_interest: this.props.attributes_of_interest, dataset_type:this.props.dataset_type}).then(res =>{this.props.set_projection_data(res["message"])})
  }

  componentDidUpdate() {
    /* if (Object.entries(this.props.cluster_data_filtered).length > 0) {
        this.create_scatter_plot(Object.entries(this.props.cluster_data_filtered))
    } */
    console.log("Number of Datasets ",(this.props.dataset_list).length);
   // console.log(this.props.projection_data);
    this.create_scatter_plot(this.props.projection_data, this.props.dataset_list, this.props.myid, this.props.cluster);
  }
  shouldComponentUpdate(nextProps, nextState){
    return true
  }
  calculate_stroke = (circle_color) => {
    var rgb = circle_color.replace(/[^\d,]/g, '').split(',');
    if(rgb[0]>=240 && rgb[2]<=139){rgb[0] = parseInt((parseInt(rgb[0])+139)/2); rgb[2]=0;} //for extremely high values of red
    else if(rgb[2]>=240 && rgb[0]<=139){rgb[2] = parseInt((parseInt(rgb[2])+139)/2); rgb[0]=0;} //for extremely high values of blue
    else if(rgb[0] - rgb[2] >65){rgb[0] = 139; rgb[2]=0;} //normal case
    else{rgb[2] = 139; rgb[0]=0;}
    var temp_color = "rgb("+rgb[0]+",0,"+rgb[2]+")";
    return temp_color;
  }
  create_scatter_plot = (data, dataset_list,myid, cluster) => {
    
    var existing_datasets = data.map(d => d.index);
    var fill_color_individual = "rgb(245,52,54)";
    var fill_color_aggregated = "rgb(145,62,54)";
    var stroke_color_individual = this.calculate_stroke(fill_color_individual);
    var stroke_color_aggregated = this.calculate_stroke(fill_color_aggregated);
    for (var i = 0; i < existing_datasets.length; i++) {
      // eslint-disable-next-line no-loop-func
      if (!dataset_list.includes(existing_datasets[i])) { data = data.filter(el => el.index !== existing_datasets[i]) }
    } //filtering out not selected data
    //console.log("data ",data);
    var data_individual = data.filter(el => el.labels === this.props.cluster && el.type === "Individual" );
    var data_aggregated = data.filter(el => el.labels === this.props.cluster && el.type === "Aggregated" );
    var data_useless = data.filter(el => el.labels !== this.props.cluster );
    /* console.log("data individual ",data_individual);
    console.log("data aggregated ",data_aggregated);
    console.log("data useless ",data_useless); */
    /* svg
      .data(data)
      .join('circle')
        .attr('cx',(d) => xAxis(d.x))
        .attr('cy',(d) => yAxis(d.y))
        .attr('r', 3.5)
        .style("fill", "#69b3a2") */

    var tooltip = d3.select("body").append("div")
      .attr("class", "tooltip")
      .style("opacity", 0);
    var margin = { top: 40, right: 10, bottom: 30, left: 10 }, //top: 10, right: 80, bottom: 30, left: 60
    width = 560 - margin.left - margin.right,
    height = 200 - margin.top - margin.bottom;//250

    // append the svg object to the body of the page
    var svg = d3.select("#my_dataviz_"+myid)
        .attr("width","100%")
        //.attr("height","100%")
        //.attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
    width = parseInt((svg.style("width")).replace("px","")) - margin.left - margin.right; 
    //height = parseInt((svg.style("height")).replace("px","")) - margin.top - margin.bottom; 
    svg.selectAll("*").remove(); //clearing contents before re-drawing; need to do this since some old contents are having same value and this would create a problem
    
    //find cluster color
    var selected_color = ((data.filter(k => k.labels === cluster)).map(d => d.color))[0];
    
    //create pattern definition
    svg.append('defs')
        .append('pattern')
          .attr('id', 'diagonal-stripe-3_'+myid)
          .attr('patternUnits', 'userSpaceOnUse')
          .attr('width', 4) //4
          .attr('height', 4)//4
        .append('path')
          .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
          .attr('stroke', selected_color) //000000
          .attr('stroke-width', 1);

    var temp_x = data.map(d => d.x);
    var xmin = d3.min(temp_x);
    var xmax = d3.max(temp_x);
    var x = d3.scaleLinear()
            .domain([xmin - Math.abs(xmin * 0.05), xmax + Math.abs(xmax * 0.05)])
            .range([0, width]); //width -50

        // Add Y axis
    var temp_y = data.map(d => d.y)
    var ymin = d3.min(temp_y);
    var ymax = d3.max(temp_y);
    var y = d3.scaleLinear()
            .domain([ymin - Math.abs(ymin * 0.05), ymax + Math.abs(ymax * 0.05)])
            .range([height, 0]);//height, 10
    
    //Dots are drawn in order in which they are to be shown; since individual dots are to be shown on top, they are drawn last   
    //drawing dots_useless
    var circs1 = svg.selectAll("dots_useless").data(data_useless) 
    circs1.join(
      enter =>
        enter
          .append("circle")
          .attr("cx", d => x(d.x))
          .attr("cy", d => y(d.y))
          .attr("r", 4.5)
          //.style("fill", d => (d.labels === this.props.cluster)?((d.type !== "Aggregated")?d.color:'url(#diagonal-stripe-3_'+myid+')'):"rgb(235,235,235)" ) //"#69b3a2" //d.color
          //.style("fill", d => (d.labels === this.props.cluster)?((d.type !== "Aggregated")?fill_color_individual:fill_color_aggregated):"rgb(235,235,235)" ) //Using red rgb(245,52,54) color for individual datasets and lighter red rgb(210,52,54) for aggregated ones
          .style("fill", "rgb(235,235,235)" )
          .attr("Type", d => d.type)
          .attr("class", "dots_useless")
          .style("stroke", undefined)
          //.style("stroke", d => (d.labels === this.props.cluster)?((d.type !== "Aggregated")?stroke_color_individual:stroke_color_aggregated):undefined)
          //.style("stroke", d => (d.labels === this.props.cluster)?"rgb(30,30,30)":undefined) //adding stroke to dots of only that cluster; black is a better choice as it looks better than "rgb(235,235,235)"
          //.style("stroke", d => (d.type === "Aggregated") ? "black" : undefined)
          .on("mouseover", function (d) { //change this to function (event,d) when upgrading d3 versions above 5.16.0
            console.log(d)
            tooltip
                .style("opacity", .9);
            tooltip.html(d.index+"<br>"+"Cluster: "+d.labels)
                .style("left", (d3.event.pageX + 5) + "px") //change this to only event.pageX + 5 when upgrading d3 versions above 5.16.0
                .style("top", (d3.event.pageY - 28) + "px");
          })
          .on("mouseout", function (d) {
            tooltip
                //.transition()
                //.duration(500)
                .style("opacity", 0);
        })
        ,
      update =>
        update
        .transition()
        .duration(2000)
        .attr("cx", d => x(d.x))
        .attr("cy", d => y(d.y))
        .attr("r", 4.5)
        .style("fill", "rgb(235,235,235)" )
        .attr("Type", d => d.type)
        .style("stroke", undefined)
        //.style("stroke", d => (d.labels === this.props.cluster)?"rgb(30,30,30)":undefined)
        //.style("stroke", d => (d.type === "Aggregated") ? "black" : undefined)

    );
    //drawing dots_aggregated
    var circs2 = svg.selectAll("dots_aggregated").data(data_aggregated) 
    circs2.join(
      enter =>
        enter
          .append("circle")
          .attr("cx", d => x(d.x))
          .attr("cy", d => y(d.y))
          .attr("r", 4.5)
          //.style("fill", d => (d.labels === this.props.cluster)?((d.type !== "Aggregated")?d.color:'url(#diagonal-stripe-3_'+myid+')'):"rgb(235,235,235)" ) //"#69b3a2" //d.color
          //.style("fill", d => (d.labels === this.props.cluster)?((d.type !== "Aggregated")?fill_color_individual:fill_color_aggregated):"rgb(235,235,235)" ) //Using red rgb(245,52,54) color for individual datasets and lighter red rgb(210,52,54) for aggregated ones
          .style("fill",fill_color_aggregated)
          .attr("Type", d => d.type)
          //.attr("class",d => {if(d.labels === this.props.cluster && d.type === "Individual" ){return "dots_individual"} else if(d.labels === this.props.cluster && d.type === "Aggregated"){return "dots_aggregated"} else{return "dots_useless"};})
          .attr("class","dots_aggregated")
          .style("stroke",stroke_color_aggregated)
          //.style("stroke", d => (d.labels === this.props.cluster)?((d.type !== "Aggregated")?stroke_color_individual:stroke_color_aggregated):undefined)
          //.style("stroke", d => (d.labels === this.props.cluster)?"rgb(30,30,30)":undefined) //adding stroke to dots of only that cluster; black is a better choice as it looks better than "rgb(235,235,235)"
          //.style("stroke", d => (d.type === "Aggregated") ? "black" : undefined)
          .on("mouseover", function (d) { //change this to function (event,d) when upgrading d3 versions above 5.16.0
            console.log(d)
            tooltip
                .style("opacity", .9);
            tooltip.html(d.index+"<br>"+"Cluster: "+d.labels)
                .style("left", (d3.event.pageX + 5) + "px") //change this to only event.pageX + 5 when upgrading d3 versions above 5.16.0
                .style("top", (d3.event.pageY - 28) + "px");
          })
          .on("mouseout", function (d) {
            tooltip
                //.transition()
                //.duration(500)
                .style("opacity", 0);
        })
        ,
      update =>
        update
        .transition()
        .duration(2000)
        .attr("cx", d => x(d.x))
        .attr("cy", d => y(d.y))
        .attr("r", 4.5)
        .style("fill",fill_color_aggregated)
        //.style("fill", d => (d.labels === this.props.cluster)?((d.type !== "Aggregated")?fill_color_individual:fill_color_aggregated):"rgb(235,235,235)" )
        .attr("Type", d => d.type)
        .style("stroke",stroke_color_aggregated)
        //.style("stroke", d => (d.labels === this.props.cluster)?((d.type !== "Aggregated")?stroke_color_individual:stroke_color_aggregated):undefined)
        //.style("stroke", d => (d.labels === this.props.cluster)?"rgb(30,30,30)":undefined)
        //.style("stroke", d => (d.type === "Aggregated") ? "black" : undefined)

    );
     //drawing dots_individual
     var circs3 = svg.selectAll("dots_individual").data(data_individual) 
     circs3.join(
       enter =>
         enter
           .append("circle")
           .attr("cx", d => x(d.x))
           .attr("cy", d => y(d.y))
           .attr("r", 4.5)
           //.style("fill", d => (d.labels === this.props.cluster)?((d.type !== "Aggregated")?d.color:'url(#diagonal-stripe-3_'+myid+')'):"rgb(235,235,235)" ) //"#69b3a2" //d.color
           //.style("fill", d => (d.labels === this.props.cluster)?((d.type !== "Aggregated")?fill_color_individual:fill_color_aggregated):"rgb(235,235,235)" ) //Using red rgb(245,52,54) color for individual datasets and lighter red rgb(210,52,54) for aggregated ones
           .style("fill",fill_color_individual)
           .attr("Type", d => d.type)
           //.attr("class",d => {if(d.labels === this.props.cluster && d.type === "Individual" ){return "dots_individual"} else if(d.labels === this.props.cluster && d.type === "Aggregated"){return "dots_aggregated"} else{return "dots_useless"};})
           .attr("class","dots_individual")
           .style("stroke",stroke_color_individual)
           //.style("stroke", d => (d.labels === this.props.cluster)?((d.type !== "Aggregated")?stroke_color_individual:stroke_color_aggregated):undefined)
           //.style("stroke", d => (d.labels === this.props.cluster)?"rgb(30,30,30)":undefined) //adding stroke to dots of only that cluster; black is a better choice as it looks better than "rgb(235,235,235)"
           //.style("stroke", d => (d.type === "Aggregated") ? "black" : undefined)
           .on("mouseover", function (d) { //change this to function (event,d) when upgrading d3 versions above 5.16.0
             console.log(d)
             tooltip
                 .style("opacity", .9);
             tooltip.html(d.index+"<br>"+"Cluster: "+d.labels)
                 .style("left", (d3.event.pageX + 5) + "px") //change this to only event.pageX + 5 when upgrading d3 versions above 5.16.0
                 .style("top", (d3.event.pageY - 28) + "px");
           })
           .on("mouseout", function (d) {
             tooltip
                 //.transition()
                 //.duration(500)
                 .style("opacity", 0);
         })
         ,
       update =>
         update
         .transition()
         .duration(2000)
         .attr("cx", d => x(d.x))
         .attr("cy", d => y(d.y))
         .attr("r", 4.5)
         .style("fill",fill_color_individual)
         .attr("Type", d => d.type)
         .style("stroke",stroke_color_individual)
         //.style("stroke", d => (d.labels === this.props.cluster)?"rgb(30,30,30)":undefined)
         //.style("stroke", d => (d.type === "Aggregated") ? "black" : undefined)
 
     );
    //var lasso = d3lasso.lasso()
    var lasso = d3lasso.lasso()
            .closePathSelect(true)
            .closePathDistance(100)
            .items(svg.selectAll('circle')) // circle //circs
            .targetArea(svg)
            .on("start", () => {
                lasso.items()
                    //.attr("r", 4) // reset size
                    .classed("not_possible", true)
                    .classed("selected", false);
            })
            .on("draw", () => {
                // Style the possible dots
                lasso.possibleItems()
                    .classed("not_possible", false)
                    .classed("possible", true);

                // Style the not possible dot
                lasso.notPossibleItems()
                    .classed("not_possible", true)
                    .classed("possible", false);
            })
            .on("end", () => {
               //console.log(lasso.selectedItems().data())
               d3.selectAll(".attributes_xaxis").remove();
                if ((lasso.selectedItems().data()).length>0 && (lasso.selectedItems().data()).length<=25) {
                console.log(lasso.selectedItems().data().map(item => item["index"]));
                this.props.set_selected_datasets(lasso.selectedItems().data().map(item => item["index"])) //item[0]
                //console.log(lasso.selectedItems().data().map(item => item[0]))
                lasso.items()
                    .classed("not_possible", false)
                    .classed("possible", false);

                // Style the selected dots
                lasso.selectedItems()
                    .classed("selected", true)
                    //.attr("border", "1px solid black")
                    //.attr("r", 4);

                // Reset the style of the not selected dots
                lasso.notSelectedItems()
                    .attr("opacity", 0.2) //0.2
                    //.attr("r", 4)
                    .transition()
                    .duration(3500)
                    .attr("opacity", 1); //1
            }// end of if
            else if((lasso.selectedItems().data()).length>0 && (lasso.selectedItems().data()).length>25){
                alert("The number of selected datasets is too high("+(lasso.selectedItems().data()).length+"). Select less than 25 datasets");
            }//end of else if
            })
        svg.call(lasso);   
        
       

  }

  render() {
    var theid = "my_dataviz_"+this.props.myid;
    return (
        
        <svg  id={theid} style={{border:"1px solid rgb(188,188,188)"}}></svg>
    )
  }// end of render
} //end of class


const maptstateToprop = (state) => {
  return {
      url: state.url,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      set_selected_datasets: (val) => dispatch({ type: "selected_datasets", value: val }),
  }
}

export default connect(maptstateToprop, mapdispatchToprop)(Scatterplot);

