import React, {Component} from 'react';
import { connect } from "react-redux";
import Grid from '@material-ui/core/Grid';
import * as jsonCall from "../../Algorithms/JSONCall";
//import Toolbar from '@material-ui/core/Toolbar';
//import {makeStyles } from '@material-ui/core/styles';
/* import Button from "@material-ui/core/Button";
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar' */
import { Card, CardGroup} from 'react-bootstrap';
import WindowedSelect from "react-windowed-select";
import Button from 'react-bootstrap/Button';
import RiskView from '../RiskView/RiskView';
import UpdateOrder from "../RiskView/UpdateOrder";
import 'bootstrap/dist/css/bootstrap.min.css';

/* const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
})); */




export class  CardRight extends Component {
  //const classes = useStyles();
  constructor(props) {
    super(props);
    
}
componentDidMount() {
    /* if (Object.entries(this.props.cluster_data_filtered).length > 0) {
        this.create_scatter_plot(Object.entries(this.props.cluster_data_filtered))
    } */
   
}
componentDidUpdate() {
    /* if (Object.entries(this.props.cluster_data_filtered).length > 0) {
        this.create_scatter_plot(Object.entries(this.props.cluster_data_filtered))
    } */

}
shouldComponentUpdate(nextProps, nextState){
    return true
}

sort_object = (dict) => {
  var items = Object.keys(dict).map(function (key) {
    return [key, dict[key]];
  });
  items.sort(function (first, second) {
    return second[1] - first[1];
  });
  return items;
}





render(){ 
//const { selected_list } = this.state;  
var break_character = <div><br /></div>
return (
    
  <Card>
  <Card.Header><Grid container spacing={0}>
    <Grid item xs={10}>Compare Risks between Dataset Combinations</Grid>
    <Grid item xs={2}><UpdateOrder></UpdateOrder></Grid>
    </Grid> </Card.Header>
  <Card.Body>
  {this.props.original_data!=null?<RiskView></RiskView>:null}
  </Card.Body>

  </Card>
  



    
  );
 } //return ends
}

const maptstateToprop = (state) => {
  return {
      url: state.url,
      attributes_of_interest: state.attributes_of_interest,
      attributes_of_interest_temp: state.attributes_of_interest_temp,
      tags_dict2:state.tags_dict2,
      datasets_with_url: state.datasets_with_url,
      isLoading: state.isLoading,
      isLoadingTags: state.isLoadingTags,
      keywords_datasets_tag: state.keywords_datasets_tag,
      clicked_tags: state.clicked_tags,
      cluster_data: state.cluster_data,
      cluster_data_filtered: state.cluster_data_filtered,
      original_data: state.original_data,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      set_selected_datasets: (val) => dispatch({ type: "selected_datasets", value: val }),
      set_final_selected_datasets: (val) => dispatch({ type: "final_selected_datasets", value: val }),
      set_attributes_of_interest: (val) => dispatch({ type: "attributes_of_interest", value: val }),
      set_attributes_of_interest_temp: (val) => dispatch({ type: "attributes_of_interest_temp", value: val }),
      set_projection_data: (val) => dispatch({ type: "projection_data", value: val }),
      set_isLoading: (val) => dispatch({ type: "isLoading", value: val }),
      set_isLoadingTags: (val) => dispatch({ type: "isLoadingTags", value: val }),
      set_dataset_list: (val) => dispatch({ type: "dataset_list", value: val }),
  }
}

export default connect(maptstateToprop, mapdispatchToprop)(CardRight);
