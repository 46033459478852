import React, {Component} from 'react';
import { connect } from "react-redux";
//import Toolbar from '@material-ui/core/Toolbar';
//import {makeStyles } from '@material-ui/core/styles';
/* import Button from "@material-ui/core/Button"; */
import { Navbar, Nav, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import * as jsonCall from "../../Algorithms/JSONCall";
import WindowedSelect from "react-windowed-select";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Tags from "./Tags";
import PlotSelector from "./PlotSelector";
import UpdateButton from "./UpdateButton";
import PortalSelection from "./PortalSelection";

/* const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
})); */

export class  SearchAppBar extends Component {
  //const classes = useStyles();
  constructor(props) {
    super(props);
    this.handleDatasetTypeChange = this.handleDatasetTypeChange.bind(this);
    
}
componentDidMount() {}
componentDidUpdate() {}
shouldComponentUpdate(nextProps, nextState){
    return true
}
handleDatasetTypeChange=(event) => {
  console.log(event.target.value);
  var temp = this.props.dataset_type_temp;
  if(temp.includes(event.target.value)){temp = temp.filter(item => item !== event.target.value)}
  else{temp.push(event.target.value)}
  this.props.set_dataset_type_temp(temp);
  console.log(this.props.dataset_type_temp);
}

render(){ 
//const { selected_list } = this.state;  
var break_character = <div><br /></div>
return (
  <Navbar bg="light" variant="light" sticky="top" expand="lg">
  <Container style={{margin:0}}>
    <Navbar.Brand ><b>PRIVEE</b></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav  className="me-auto">
        <Nav.Item style={{marginRight:10, marginTop:8}}>
        <input type="checkbox" id="checkbox_individual" name="datasets_type" value="Individual" onChange={this.handleDatasetTypeChange} defaultChecked></input>
        <label for="checkbox_individual">Individual Datasets</label>
        </Nav.Item>
        <Nav.Item style={{marginRight:10, marginTop:8}}>
        <input type="checkbox" id="checkbox_aggregate" name="datasets_type" value="Aggregated" onChange={this.handleDatasetTypeChange} ></input>
        <label for="checkbox_aggregate">Aggregated Datasets</label>
        </Nav.Item>
        <Nav.Item style={{width: 200, height:5, marginRight:10}}>
        <PlotSelector></PlotSelector>
        </Nav.Item>
        <Nav.Item style={{width: 400, height:5, marginRight:10}}>
        <Tags></Tags>
        </Nav.Item>
        <Nav.Item>
        <PortalSelection></PortalSelection>
        </Nav.Item>
        <Nav.Item>
        <UpdateButton></UpdateButton>
        </Nav.Item>
       
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
    
  );
 } //return ends
}

const maptstateToprop = (state) => {
  return {
      url: state.url,
      dataset_type_temp: state.dataset_type_temp,
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      set_selected_datasets: (val) => dispatch({ type: "selected_datasets", value: val }),
      set_dataset_type_temp: (val) => dispatch({ type: "dataset_type_temp", value: val }),
  }
}

export default connect(maptstateToprop, mapdispatchToprop)(SearchAppBar);

/* export default function SearchAppBar() {
  //const classes = useStyles();
  return (
    

<Navbar bg="light" variant="light" sticky="top" expand="lg">
  <Container style={{margin:0}}>
    <Navbar.Brand ><b>PRIVEE</b></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav  className="me-auto">
        <Nav.Item>
        Individual Dataset
        </Nav.Item>
        <Nav.Item>
        Aggregated Dataset
        </Nav.Item>
        <Nav.Item>
        <Nav.Link href="" >Aggregated Datasets</Nav.Link>
        </Nav.Item>
        
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
    
  );
} */
