/* eslint-disable no-unused-vars */

import React, { Component } from 'react';
import { connect } from "react-redux";
import * as $ from "jquery"
import * as d3 from "d3"
import Dialogs from './Dialogue';
import "./Item.css";

class Item extends Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(props) {
        super(props)
        this.state = { dialogue_data: { open: false, item: null } };
    }
    componentDidUpdate() {
        var div = d3.select("body").selectAll(".tooltip").data([0]).join('div').attr("class", "tooltip").style("opacity", 0);
        var self = this
        var config = this.props.config
        var parent_width = $("." + this.props.class_name).width()
        var margin1 = { left: 10, right: 10, top: 40, bottom: 20 } // This margin1 is the first
        var width = parent_width - (margin1.left + margin1.right)
        var item_height = 95;//80
        var item_width = width - (config.space_for_dataset_name + config.space_for_risk_score)
        var temp_risk_view_data = [...this.props.sorted_risk_view_data].sort((a, b) => b[1].length - a[1].length) // This is to find the longest one
        var max_textsize = 16
        var entropy_array = self.props.item_data[5];
        //----------------------------------------------------------------------------------------------------------------
        var svg1 = d3.select("." + this.props.class_name).attr("height", item_height)
        /* svg1.attr("add_radio", function(){
            d3.select(this).selectAll(".my_radio").data([0]).join('input').attr("class","my_radio").attr("type","radio").attr("value","d").attr("name","radio-button-demo1").style("color","default")
        }) */

        svg1.attr("add_left_dataset_name", function () {
            var my_text = self.props.item_data[0][0]
            var ldn_width = config.space_for_dataset_name - 20
            d3.select(this).selectAll(".left_dataset_name").data([0]).join("foreignObject").attr("class", "left_dataset_name").attr("x", 0).attr("y", 0)
                .attr("width", ldn_width)
                .attr("height", item_height)
                .selectAll('.foreigndiv').data([0]).join("xhtml:div").attr('class', 'foreigndiv')
                .style("width", "100%")
                .style("height", "100%")
                .html("<p style='text-align:left'>" + my_text + "</p>")
                .on("click", function () {
                    if (d3.select(this).classed("opened")) { d3.select("." + self.props.div_class).selectAll(".unmatched_attrs_container").remove(); d3.select(this).classed("opened", false) }
                    else {
                        d3.select(this).classed("opened", true)
                        var clicked_dataset = self.props.item_data[0][0]
                        var all_attributes = self.props.original_data[clicked_dataset]['Columns Field Name'].filter((columns_name) => !columns_name.startsWith(":@computed"));
                        all_attributes = all_attributes.map(attr => attr.replaceAll("_", ""));
                        var temp_matched_attrs = self.props.item_data[1].map(item => item[0])
                        var unmatched_attrs = all_attributes.filter(item => !temp_matched_attrs.includes(item))
                        d3.select("." + self.props.div_class).selectAll(".unmatched_attrs_container").data([0]).join('div').attr("class", "unmatched_attrs_container").style('width', (width - config.space_for_risk_score) + "px")
                            .selectAll('.unmatched_attrs').data(unmatched_attrs).join('p').attr('class', 'unmatched_attrs').text(d => d)
                            .style('color', d => self.props.clicked_unmatched.includes(d) ? '#c1b807' : 'black')
                            .on("click", function (d) {
                                if (self.props.clicked_unmatched.includes(d)) { self.props.set_clicked_unmatched(self.props.clicked_unmatched.filter(item => item != d)) }
                                else { self.props.set_clicked_unmatched([...self.props.clicked_unmatched, d]) }
                                d3.select(this).style('color', d => self.props.clicked_unmatched.includes(d) ? '#c1b807' : 'black')
                            })
                    }
                })
        })

        svg1.attr("add_right_dataset_name", function () {
            var my_text = self.props.item_data[0][1]
            var rdn_width = config.space_for_dataset_name - 20
            d3.select(this).selectAll(".right_dataset_name").data([0]).join("foreignObject").attr("class", "right_dataset_name")
                .attr("x", item_width + 10).attr("y", 0)
                .attr("width", rdn_width)
                .attr("height", item_height)
                .selectAll('.foreigndiv').data([0]).join("xhtml:div").attr('class', 'foreigndiv')
                .style("width", "100%")
                .style("height", "100%")
                .html("<p style='text-align:right'>" + my_text + "</p>")

                .on("click", function () {
                    if (d3.select(this).classed("opened")) { d3.select("." + self.props.div_class).selectAll(".unmatched_attrs_container").remove(); d3.select(this).classed("opened", false) }
                    else {
                        d3.select(this).classed("opened", true)
                        var clicked_dataset = self.props.item_data[0][1]
                        var all_attributes = self.props.original_data[clicked_dataset]['Columns Field Name'].filter((columns_name) => !columns_name.startsWith(":@computed"));
                        all_attributes = all_attributes.map(attr => attr.replaceAll("_", ""));
                        var temp_matched_attrs = self.props.item_data[1].map(item => item[0])
                        var unmatched_attrs = all_attributes.filter(item => !temp_matched_attrs.includes(item))
                        d3.select("." + self.props.div_class).selectAll(".unmatched_attrs_container").data([0]).join('div').attr("class", "unmatched_attrs_container").style('width', (width - config.space_for_risk_score) + "px")
                            .selectAll('.unmatched_attrs').data(unmatched_attrs).join('p').attr('class', 'unmatched_attrs').text(d => d)

                    }
                })
        })
        var rect_width = ((item_width - config.space_for_dataset_name) / temp_risk_view_data[0][1].length) * self.props.item_data[1].length;

        var available_height = item_height / 2.1; //item_height - (item_height/3);
        var x_entropy = d3.scaleBand().domain(entropy_array.map(d => d[0])).range([0, rect_width]);
        var y_entropy = d3.scaleLinear().domain(d3.extent(entropy_array, d => d[1])).range([available_height, 0]);

        //d3.selectAll(".bottom_axis_g_attributes").remove();
        svg1.selectAll(".bottom_axis_g_attributes").data([0]).join("g").attr("class", "bottom_axis_g_attributes").call(d3.axisBottom(x_entropy).tickSize(0)).attr("class", "bottom_axis_g_attributes").attr("transform", (d, i) => { return "translate(" + config.space_for_dataset_name + "," + item_height / 2.1 + ")" })
            .selectAll("text")
            .attr("transform", "translate(0,0)rotate(-90)")
            .style("text-anchor", "start")
            .attr("dy", ".15em")
            .attr("dx", "-4em")
            .text((d) => { return d.length >= 9 ? d.substring(0, 6) + '...' : d })
            .attr("font-size", config.fontSize)
            .style("fill", d => self.props.clicked_matched.includes(d) || self.props.attributes_for_match_view.includes(d) ? '#c1b807' : '#212529') //bronze yellow or Buddha Gold :'#c1b807'; #9C109A: is dark magents
            .attr("font-weight", d => self.props.clicked_matched.includes(d) ? 800 : 400)
            .style("cursor", "pointer")
            .on("click", d => {
                if (self.props.clicked_matched.includes(d)) { self.props.set_clicked_matched(self.props.clicked_matched.filter(item => item != d)) }
                else { self.props.set_clicked_matched([...self.props.clicked_matched, d]) }

            })
            .on("mouseover", d => {
                div.transition().duration(200).style("opacity", .9);
                div.html(d).style("left", (d3.event.pageX) + "px").style("top", (d3.event.pageY + 20) + "px")
            })
            .on("mouseout", d => div.transition().duration(200).style("opacity", 0));
        svg1.select(".attributes_xaxis").lower();
        //svg1.selectAll(".bars_attributes").data(entropy_array).join("rect").attr("class", "bars_attributes").attr("width",(d) => available_height - y_entropy(d[1])).attr("height",x_entropy.bandwidth()).attr("x",(d,i) =>config.space_for_dataset_name+(i*x_entropy.bandwidth()));
        svg1.selectAll(".bars_attributes").data(entropy_array).join("rect").attr("class", "bars_attributes").attr("width", (d) => x_entropy.bandwidth()).attr("height", (d) => available_height - y_entropy(d[1])).attr("x", (d, i) => { return config.space_for_dataset_name + x_entropy(d[0]) }).attr("y", (d, i) => y_entropy(d[1]))
            .style("fill", (d) => { if (self.props.attributes_of_interest.includes(d[0])) { return "#ff8787" } else { return "rgb(190,190,190)" } })
            .style("cursor", "pointer")
            .on("click", d => {
                if (self.props.clicked_matched.includes(d[0])) { self.props.set_clicked_matched(self.props.clicked_matched.filter(item => item != d[0])) }
                else { self.props.set_clicked_matched([...self.props.clicked_matched, d[0]]) }

            });
        //--------- New Attributes Design End

        //-----------------------Risk score
        var riskpoint_scale = d3.scaleLinear().domain([0, 181]).range([0, 5])
        var risk_score = parseInt(riskpoint_scale((self.props.item_data[3] > 181) ? 181 : self.props.item_data[3]))
        var x_start = width - config.space_for_risk_score + 15
        var risk_score_width = config.space_for_risk_score
        var risk_score_ractangle_width = risk_score_width / 5
        var risk_score_x_scale = d3.scaleLinear().domain([0, 181]).range([0, (risk_score_ractangle_width * 5)])
        svg1.selectAll(".left_text").data([0]).join("text").attr("class", "left_text").text("Low").attr("x", (d, i) => i * risk_score_ractangle_width + x_start).attr("y", item_height / 2 + 20).style('font-size', "0.5em");
        svg1.selectAll(".risk_points").data([0]).join("rect").attr("class", "risk_points")
            .attr("x", (d, i) => i * risk_score_ractangle_width + x_start).attr("y", item_height / 2 - 8).attr("height", 16).attr('width', risk_score_ractangle_width * 5)/* .style("fill", "white") */.style("fill", "url(#grad)")
            .attr('stroke', 'black').attr('stroke-width', 1);
        //svg1.selectAll(".risk_arrow").data([0]).join("rect").attr("class", "risk_arrow")  
        svg1.selectAll(".risk_score_axis").remove();
        svg1.selectAll(".bottom_axis_g_risk").data([0]).join("g").attr("class", "bottom_axis_g_risk").call(d3.axisBottom(risk_score_x_scale).tickSize(30).tickValues([(self.props.item_data[3] > 181) ? 181 : self.props.item_data[3]])).attr("class", "risk_score_axis").attr("transform", (d, i) => { var translate_x = i * risk_score_ractangle_width + x_start; var translate_y = (item_height / 3); return "translate(" + translate_x + "," + translate_y + ")" });
        svg1.selectAll(".right_text").data([0]).join("text").attr("class", "right_text").text("High").attr("x", (d, i) => i * risk_score_ractangle_width + x_start + risk_score_ractangle_width * 4).attr("y", item_height / 2 + 20).style('font-size', "0.5em");

        d3.selectAll(".risk_score_axis").select("path").remove();
        d3.selectAll(".risk_score_axis").select(".tick").select("text").remove();
        d3.selectAll(".risk_score_axis").select(".tick").select("line").style("stroke-width", 2);

        //------------------------------------------------------------ Attributes ends here
    }
    set_dialogue_data = (dialogue_data) => { this.setState({ dialogue_data: dialogue_data }) }
    getTextSplitted = (text, width, number_of_lines) => {
        var temp = []
        var number_of_words = text.split(' ').length / number_of_lines
        var count = 0
        for (var i = 0; i < number_of_lines; i++) {
            if (i == 3) { temp.push(text.split(' ').slice(count, text.split(' ').length).join(' ')) }
            else { temp.push(text.split(' ').slice(count, count + number_of_words).join(' ')) }
            count += number_of_words
        }
        return temp
    }
    componentDidMount() {
        this.setState({ a: 10 })
    }
    render() {
        return (
            <div>
                <svg className={this.props.class_name} style={{ width: $('.risk_view_container').width() - 90 }}></svg>
                <Dialogs attributes_of_interest={this.props.attributes_of_interest} set_attributes_of_interest={this.props.set_attributes_of_interest} set_dialogue_data={this.set_dialogue_data} dialogue_data={this.state.dialogue_data}></Dialogs>
            </div>

        );
    }
};
const maptstateToprop = (state) => {
    return {
        original_data: state.original_data,
        selected_datasets: state.selected_datasets,
        attributes_of_interest: state.attributes_of_interest,
        clicked_matched: state.clicked_matched,
        clicked_unmatched: state.clicked_unmatched,
        attributes_for_match_view: state.attributes_for_match_view,
    }
}
const mapdispatchToprop = (dispatch) => {
    return {
        set_clicked_matched: (val) => dispatch({ type: "clicked_matched", value: val }),
        set_clicked_unmatched: (val) => dispatch({ type: "clicked_unmatched", value: val }),
        set_attributes_of_interest: (val) => dispatch({ type: "attributes_of_interest", value: val }),
    }
}
export default connect(maptstateToprop, mapdispatchToprop)(Item);
