import React, {useState} from 'react';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import { connect } from "react-redux";
import * as jsonCall from "../../Algorithms/JSONCall";
import Button from 'react-bootstrap/Button';
import _ from 'lodash';
import 'bootstrap/dist/css/bootstrap.min.css';

const useStyles = makeStyles((theme) => ({
  portalpop:{
    boxShadow:"none",
    borderRadius:"0 0 10px 10px",
    //top:"48px!important",
    border:"1px solid grey",
    borderTop:"none",
    padding:"20px",
    maxWidth:"95vw",
    maxHeight:'60vh',
    backgroundColor:"#F2F2F2",
  },
}));

export function  UpdateOrder(props) {
  const classes = useStyles();
  
  const handleUpdateOrder = (event) => {
    props.set_clicked_matched2(props.clicked_matched);
    console.log(props.clicked_matched2);
  };
  
  
return (
    
  <Button size="sm" style={{ backgroundColor: "#E0E0E0", opacity: 1, borderRadius: 3, color: "black", border:0,  marginLeft: 1, marginRight:15, marginTop:0, marginBottom:0}}
            onClick={handleUpdateOrder} 
          >Update Order</Button>
  
    
  );
 
}

const maptstateToprop = (state) => {
  return {
      url: state.url,  
      clicked_matched: state.clicked_matched,
      clicked_matched2: state.clicked_matched2,    
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      set_clicked_matched2: (val) => dispatch({ type: "clicked_matched2", value: val }),
      
  }
}

export default connect(maptstateToprop, mapdispatchToprop)(UpdateOrder);
