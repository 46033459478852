import React from 'react';
import Modal from '@material-ui/core/Modal';
import Table from './Table'
import Button from '@material-ui/core/Button';
export default function SimpleModal(props) {
    const handleClose = () => {
        props.set_table_open(false)
    };
    return (
        <div>
            <Modal style={{margin:5,backgroundColor:'white',height:window.innerHeight/2 -27,marginTop:window.innerHeight/2}} open={props.table_open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <Table set_table_open={props.set_table_open} attributes_of_interest={props.attributes_of_interest} table_data={props.table_data}></Table>
            </Modal>
        </div>
    );
}
