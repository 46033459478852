import React, {Component} from 'react';
import { connect } from "react-redux";
import * as jsonCall from "../../Algorithms/JSONCall";
//import { Card, CardGroup} from 'react-bootstrap';
import WindowedSelect from "react-windowed-select";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';



var attributes_list1 = ['projection plot'];
var attributes_list2 = [ 'histograms']
var options = [];
for (let i = 0; i < attributes_list1.length; i += 1) {
  options.push({
    label: attributes_list1[i],
    value: attributes_list1[i]
  });
}
var selected_list_initial = [...options];
for (let i = 0; i < attributes_list2.length; i += 1) {
  options.push({
    label: attributes_list2[i],
    value: attributes_list2[i]
  });
}

export class  Tags extends Component {
  //const classes = useStyles();
  constructor(props) {
    super(props);
    this.state = {selected_list: selected_list_initial};
    this.handleChange = this.handleChange.bind(this);
    
}
componentDidMount() {
    
   
}
componentDidUpdate() {
   

}
shouldComponentUpdate(nextProps, nextState){
    return true
}

handleChange=(selected_plot_type)=>{
  console.log(selected_plot_type);
    this.setState({ selected_list: selected_plot_type })
    var temp = selected_plot_type["value"];
    this.props.set_plot_type_temp(temp);
}
  

 



render(){ 
const { selected_list } = this.state;  
var break_character = <div><br /></div>
return (
    
    <WindowedSelect
                      options={options}
                      onChange={this.handleChange}
                      value={selected_list}
                      style={{width: 100}}
                    />
   
  
  
    
  );
 } //return ends
}

const maptstateToprop = (state) => {
  return {
      url: state.url,
      attributes_of_interest_temp: state.attributes_of_interest_temp,
      isLoading: state.isLoading,
      attributes_of_interest: state.attributes_of_interest,
      plot_type_temp: state.plot_type_temp,
      
  }
}
const mapdispatchToprop = (dispatch) => {
  return {
      set_attributes_of_interest_temp: (val) => dispatch({ type: "attributes_of_interest_temp", value: val }),
      set_isLoading: (val) => dispatch({ type: "isLoading", value: val }),
      set_attributes_of_interest: (val) => dispatch({ type: "attributes_of_interest", value: val }),
      set_projection_data: (val) => dispatch({ type: "projection_data", value: val }),
      set_plot_type_temp: (val) => dispatch({ type: "plot_type_temp", value: val }),
      
  }
}

export default connect(maptstateToprop, mapdispatchToprop)(Tags);
