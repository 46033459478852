import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import * as d3 from "d3";

// eslint-disable-next-line no-unused-vars
const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default function RadioButtons(props) { // item data contains the dataset pair
  //console.log(props.original_data[props.item_data[0][0]]['Permalink'],props.original_data[props.item_data[0][1]]['Permalink']) 
  const handleChange = (event) => {
    d3.selectAll(".attributes_xaxis").remove(); //removing all instances of the xaxis of the bars
    var temp={}
    temp[props.item_data[0][0]]=props.original_data[props.item_data[0][0]]['Permalink'];
    temp[props.item_data[0][1]]=props.original_data[props.item_data[0][1]]['Permalink'];
    props.set_checked_datasets({'checked_datasets':temp,'shared_attributes':props.item_data[1].map(item=>item[0])});
  };

  return (
    <div style={{display:"inline-block"}}>
      <Radio
        checked={props.item_data[0][0] in props.checked_datasets && props.item_data[0][1] in props.checked_datasets?true:false}
        onChange={handleChange}
        value="d"
        color="default"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'D' }}
        style={{ marginTop:"30%" }}
      />
      {/* <input
        type="radio"
        checked={props.item_data[0][0] in props.checked_datasets && props.item_data[0][1] in props.checked_datasets?true:false}
        onChange={handleChange}
        value="d"
        color="default"
        name="radio-button-demo"
        inputProps={{ 'aria-label': 'D' }}
      /> */}
    </div>
  );
}
