import React, { Component } from 'react';
import * as d3 from 'd3';
import * as d3lasso from 'd3-lasso';
import * as $ from "jquery";
import _ from 'lodash';
import { connect } from "react-redux";
import * as jsonCall from "../../Algorithms/JSONCall";
import Grid from '@material-ui/core/Grid';
import HistogramPlot from "./HistogramPlot";
//import Toolbar from '@material-ui/core/Toolbar';
//import {makeStyles } from '@material-ui/core/styles';
import "./plotArea.css";

/* const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
})); */

export default class ProjectionPlotHistogram extends Component {
  constructor(props) {
    super(props)
    this.state = {
        selected_datasets: {},
        data:{},
    }
  } // end of constructor

  componentDidMount() {
    
    //console.log("Now mounted");
    var histogram_results = this.calculateHistogramData(this.props.projection_data, this.props.original_data,this.props.attributes_of_interest,this.props.cluster,5);
    var histogram_data = histogram_results[0];
    var number_of_datasets = histogram_results[1];
    var config = { fontSize: 12, max_attr_char: 8, space_for_dataset_name: 130, space_for_risk_score: 80 }
    this.createHistogram(histogram_data, config,this.props.myid, this.props.cluster, number_of_datasets,this.props.attributes_of_interest);
    
    //jsonCall.download(this.props.url + "/api/v2/projection2", {attributes_of_interest: this.props.attributes_of_interest, dataset_type:this.props.dataset_type}).then(res =>{this.props.set_projection_data(res["message"])})
  }

  componentDidUpdate() {
    
    console.log("Number of Datasets ",(this.props.dataset_list).length);
    //console.log(this.props.projection_data);
    var histogram_results = this.calculateHistogramData(this.props.projection_data, this.props.original_data,this.props.attributes_of_interest,this.props.cluster,5);
    var histogram_data = histogram_results[0];
    var number_of_datasets = histogram_results[1];
    var config = { fontSize: 12, max_attr_char: 8, space_for_dataset_name: 130, space_for_risk_score: 80 }
    this.createHistogram(histogram_data, config,this.props.myid, this.props.cluster, number_of_datasets, this.props.attributes_of_interest);
    
  }
  shouldComponentUpdate(nextProps, nextState){
    return true
  }

  sort_object = (dict) => {
    var items = Object.keys(dict).map(function (key) {
      return [key, dict[key]];
    });
    items.sort(function (first, second) {
      return second[1] - first[1];
    });
    return items;
  }
  
  calculateHistogramData = (projection_data, original_data, attributes_of_interest, cluster,top_n_attributes) => {
    var selected_datasets = (projection_data.filter(k => k.labels === cluster)).map(d => d.index);
    var filtered = _.pickBy(original_data,(d) => {return selected_datasets.includes(d.Dataset)});
    var number_of_datasets = Object.keys(filtered).length;
    var temp1 ={};
    var temp1_others = {};
    attributes_of_interest.map(d => {temp1[d] =0})//initializing for all attributes of interest
    var temp1_initial = _.cloneDeep(temp1); //keeping a deep copy of the initial temp1
    var temp2 = [];
    for(var [key, value] of Object.entries(filtered)){
      var columns = value["Columns Field Name"].filter((columns_name) => !columns_name.startsWith(":@computed"));
      columns = columns.map(x => x.replaceAll("_",""));//replacing underscore
      // eslint-disable-next-line no-loop-func
      attributes_of_interest.map(d => {
        if(columns.includes(d)){temp1[d]=(temp1[d]>0)?temp1[d]+=1 :1}
      }) //checking count of privacy related attributes
      columns.map(d => {
        temp1_others[d]=(temp1_others[d]>0)?temp1_others[d]+=1 :1
      }) //checking count of other attributes
    }
    //delete keys of privacy related attributes
    attributes_of_interest.map(d => {
      delete temp1_others[d];
    });
    //sort the rest of the attributes
    var items = this.sort_object(temp1_others);

    //if(_.isEqual(temp1,temp1_initial)){temp1 ={}} //resetting to blank dict if there is nothing in there //no need of this
    //now adding to temp2 so that it becomes an array of array
    //adding the privacyrelated attributes
    for(var [key1,value1] of Object.entries(temp1)){
      var tempx = [key1,value1];
      temp2.push(tempx);
    }
    //adding the top 5 other attributes
    items.slice(0,top_n_attributes).map(d => temp2.push(d));

    return [temp2,number_of_datasets];
  }
  createHistogram = (histogram_data, config, myid, cluster, number_of_datasets,attributes_of_interest) => {
    // set the dimensions and margins of the graph
    var theid = "#histogram_container_"+myid;
    var margin = { top: 20, right: 30, bottom: 20, left: 60 }, //config.space_for_dataset_name //60
        width = $(theid).width() - margin.left - margin.right, //460
        height = 400 - margin.top - margin.bottom;
        //height = $(theid).height() - margin.top - margin.bottom;
    var tooltip = d3.select("body").append("div")
        .attr("class", "tooltip_attributes")
        .style("opacity", 0);
    var svg1 = d3.select(theid)
        .attr("width","100%")
        .attr("height","100%")
        //.attr("width", width + margin.left + margin.right)
        //.attr("height", height + margin.top + margin.bottom)
    var width_initial = parseInt((svg1.style("width")).replace("px",""));
    var height_initial = parseInt((svg1.style("height")).replace("px",""));
    margin.left=0.3*width_initial;
    margin.right=0.1*width_initial; //adjusting left anf right margin according to width
    margin.top=0.05*height_initial;
    margin.bottom=0.05*height_initial; //adjusting left anf right margin according to width
    width = parseInt((svg1.style("width")).replace("px","")) - margin.left - margin.right; 
    height = parseInt((svg1.style("height")).replace("px","")) - margin.top - margin.bottom;    
    console.log("Width and Height: ", width, height);
    

    //add the heading
    d3.select(theid).selectAll('.heading_text').data([0]).join('text').attr('class', 'heading_text').attr('x', width/2).attr('y',2).attr('font-size', "0.75em").attr('font-weight', 600).text('Cluster '+cluster).attr('dominant-baseline', 'hanging');
    //add the number of datasets
    d3.select(theid).selectAll('.heading_text2').data([0]).join('text').attr('class', 'heading_text2').attr('x', width/3).attr('y',20).attr('font-size', "0.75em").attr('font-weight', 600).text('Number of datasets: '+number_of_datasets).attr('dominant-baseline', 'hanging');
    
    if (histogram_data.length == 0) { /* d3.select(theid).selectAll('.empty_text2').data([0]).join('text').attr('class', 'empty_text2').attr('x', width/5).attr('y', height / 2).attr('font-size', "0.65em").attr('font-weight', 600).text('No privacy related attributes here').attr('dominant-baseline', 'hanging').attr('text-anchor', 'start'); */ } 
    else { 

      //d3.select(theid).selectAll('.empty_text2').remove() ;
    // append the svg object to the body of the page
    var svg = d3.select(theid).selectAll(".parent_g").data([0]).join("g").attr("class", "parent_g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Add X axis
    var x = d3.scaleLinear().domain([0, d3.max(histogram_data.map(item => item[1]))]).range([0, width])

    // Y axis
    var y = d3.scaleBand().range([(0.1*height), height]).domain(histogram_data.map(item => item[0])).padding(.03);
    svg.selectAll(".left_axis_g2").data([0]).join("g").attr("class", "left_axis_g2").call(d3.axisLeft(y)).selectAll("text").attr("font-size", "1.1em").text(d => {return d.length>11?d.substring(0,11)+'...':d}).style("font-weight",(d) =>{return (attributes_of_interest.includes(d))?900:"normal"}).style("color",(d) =>{return (attributes_of_interest.includes(d))?"rgb(247,135,135)":"black"})
    .on("mouseover", function (d) { //change this to function (event,d) when upgrading d3 versions above 5.16.0
      tooltip
          .style("opacity", .9);
      tooltip.html(d)
          .style("left", (d3.event.pageX + 5) + "px") //change this to only event.pageX + 5 when upgrading d3 versions above 5.16.0
          .style("top", (d3.event.pageY - 28) + "px");
    })
    .on("mouseout", function (d) {
      tooltip
          //.transition()
          //.duration(500)
          .style("opacity", 0);
  });

    //Bars
    svg.selectAll(".myRect").data(histogram_data).join("rect").attr("class", "myRect").attr("x", 0)
        .attr("y", function (d) { return y(d[0]); }).attr("width", function (d) { return x(d[1]); }).attr("height", y.bandwidth()).attr("fill", "rgb(190,190,190)") //"#969696"
    //d3.selectAll(".domain").remove()
    
    //not rotated
    svg.selectAll(".myText").data(histogram_data).join("text").attr("x", d => {return (d[1] === 0)?(3+ x(d[1])):(x(d[1]) -12)}  ).attr("class", "myText").attr('dominant-baseline',"middle") //earlier it was 3 + x(d[1])
        .attr("y", d => y(d[0]) + y.bandwidth() / 2).text(d => d[1]).attr("font-size", "0.60em").style("fill",d => {return (d[1] === 0)?"black":"white"});
    //rotated (not needed)
    /* svg.selectAll(".myText").data(histogram_data).join("text").attr("x", 0 ).attr("class", "myText").attr("transform", d => {console.log($(this));var x_loc = (d[1] === 0 )?(6+ x(d[1])):(x(d[1]) -8 ); var y_loc =y(d[0]) + (y.bandwidth() / 2) - parseInt(d[1]/10)*4; return "translate("+x_loc+","+y_loc+") rotate(90)"}).attr('dominant-baseline',"middle") //earlier it was 3 + x(d[1])
        .attr("y", 0).text(d => d[1]).attr("font-size", "0.60em").style("fill",d => {return (d[1] === 0)?"black":"white"});  */   
  }//end of else    
}

  render() {
    var theid = "histogram_container_"+this.props.myid;
    //var theid = "my_dataviz_histogram_"+this.props.myid;
    return (
      
        <svg  id={theid} style={{border:"1px solid rgb(188,188,188)"}}></svg>
    )
  }// end of render
} //end of class

