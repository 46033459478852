import React, { Component } from 'react';
import * as d3 from 'd3';
import cloud from "d3-cloud";
import WordCloud from 'react-d3-cloud';
import _ from 'lodash';
import * as $ from "jquery";
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import * as d3lasso from 'd3-lasso';
import { connect } from "react-redux";
import * as jsonCall from "../../Algorithms/JSONCall";
import Grid from '@material-ui/core/Grid';
import HistogramPlot from "./HistogramPlot";
//import Toolbar from '@material-ui/core/Toolbar';
//import {makeStyles } from '@material-ui/core/styles';
import "./plotArea.css";

/* const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
})); */

const frequency_list = [
  { text: "text", size: 10 },
  { text: "sleight", size: 12 },
  { text: "hand", size: 12 },
  { text: "magic", size: 12 },
  { text: "future", size: 14 },
  { text: "read", size: 18 },
  { text: "mind", size: 12 },
  { text: "training", size: 14 },
  { text: "amp", size: 12 },
  { text: "question", size: 10 },
  { text: "thing", size: 13 },
];

var color = d3.scaleLinear()
  .domain([0, 1, 2, 3, 4, 5, 6, 10, 15, 20, 100])
  .range([
    "#ddd",
    "#ccc",
    "#bbb",
    "#aaa",
    "#999",
    "#888",
    "#777",
    "#666",
    "#555",
    "#444",
    "#333",
    "#222"
  ]);

  const data = [
    { text: 'Hey', value: 1000 },
    { text: 'lol', value: 2000 },
    { text: 'first impression', value: 8000 },
    { text: 'very cool', value: 10000 },
    { text: 'duck', value: 5000 },
    { text: 'Hey', value: 1000 },
    { text: 'lol', value: 2000 },
    { text: 'first impression', value: 8000 },
    { text: 'very cool', value: 10000 },
    { text: 'duck', value: 5000 },
  ]; 
  var myheight;
//this.props is the variables passed by the calling component
export default class WordCloudClass extends Component {
  constructor(props) {
    super(props)
    this.state = {
        selected_datasets: {},
        data:{},
    }
  } // end of constructor

  componentDidMount() {
    
    //console.log("Now mounted");
    /* var wordcloud_results = this.calculateWordCloudData(this.props.projection_data, this.props.original_data,this.props.cluster);
    console.log(wordcloud_results); */
    this.create_wordcloud(this.props.projection_data, this.props.dataset_list, this.props.myid);
    //jsonCall.download(this.props.url + "/api/v2/projection2", {attributes_of_interest: this.props.attributes_of_interest, dataset_type:this.props.dataset_type}).then(res =>{this.props.set_projection_data(res["message"])})
  }

  componentDidUpdate() {
    
    //console.log("Number of Datasets ",(this.props.dataset_list).length);
    /* var wordcloud_results = this.calculateWordCloudData(this.props.projection_data, this.props.original_data,this.props.cluster);
    console.log(wordcloud_results); */
   // console.log(this.props.projection_data);
    this.create_wordcloud(this.props.projection_data, this.props.dataset_list, this.props.myid);
  }
  shouldComponentUpdate(nextProps, nextState){
    return true
  }

  calculateWordCloudData = (projection_data, original_data, cluster) => {
    var selected_datasets = (projection_data.filter(k => k.labels === cluster)).map(d => d.index);
    var selected_color = ((projection_data.filter(k => k.labels === cluster)).map(d => d.color))[0];
    var filtered = _.pickBy(original_data,(d) => {return selected_datasets.includes(d.Dataset)});
    var temp1 ={};
    //attributes_of_interest.map(d => {temp1[d] =0})//initializing for all attributes of interest
    
    var temp2 = [];
    for(var [key, value] of Object.entries(filtered)){
      var columns = value["Columns Field Name"].filter((columns_name) => !columns_name.startsWith(":@computed"));
      columns = columns.map(x => x.replaceAll("_",""));
      // eslint-disable-next-line no-loop-func
      columns.map(d => {
        temp1[d]=(temp1[d]>0)?temp1[d]+=1 :1
      })
    }

    var sizeScale = d3.scaleLinear()
            .domain([d3.min(Object.values(temp1)), d3.max(Object.values(temp1))])
            .range([300, 3000]);//[1000, 10000] //[600, 6000]
    
    for(var [key1,value1] of Object.entries(temp1)){
      var tempx = { text: key1, value: sizeScale(value1) };
      temp2.push(tempx);
    }
    return [temp2,selected_color];
  }
  create_wordcloud = (data, dataset_list,myid,wordcloud_results) => {
    
    var existing_datasets = data.map(d => d.index)
    for (var i = 0; i < existing_datasets.length; i++) {
      // eslint-disable-next-line no-loop-func
      if (!dataset_list.includes(existing_datasets[i])) { data = data.filter(el => el.index !== existing_datasets[i]) }
    } //filtering out not selected data
    
    
    
    var margin = { top: 40, right: 10, bottom: 30, left: 10 }, //top: 10, right: 80, bottom: 30, left: 60
    width = 560 - margin.left - margin.right,
    height = 200 - margin.top - margin.bottom;//250

    // append the svg object to the body of the page
    var svg = d3.select("#my_dataviz_wordcloud_"+myid)
        .attr("width","100%")
        //.attr("height","100%")
        //.attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
    width = parseInt((svg.style("width")).replace("px","")) - margin.left - margin.right; 
    //height = parseInt((svg.style("height")).replace("px","")) - margin.top - margin.bottom; 
    //myheight=height;
    
    
      // Constructs a new cloud layout instance. It run an algorithm to find the position of words that suits your requirements
      /* var layout = cloud()
        .size([width, height])
        //.words(myWords.map(function(d) { return {text: d}; }))
        .words(wordcloud_results)
        .padding(8)
        .fontSize((d) => Math.sqrt(d.value))
        .on("end", draw);
      layout.start();

      function draw(words) {
        svg
          .append("g")
            .attr("transform", "translate(" + layout.size()[0] / 2 + "," + layout.size()[1] / 2 + ")")
            .selectAll("text")
              .data(words)
            .enter().append("text")
              .style("font-size", function(d) { return d.size + "px"; })
              .style("fill", function(d, i) { return color(i);})
              .attr("text-anchor", "middle")
              .attr("transform", function(d) {
                return "translate(" + [d.x, d.y] + ")";
              })
              .text(function(d) { return d.text; });
      } */


  }

  render() {
    var theid = "my_dataviz_wordcloud_"+this.props.myid;
    var theid_histogram = "#histogram_container_"+this.props.myid;
    var myheight = 0.18*$(window).width(); //height of wordcloud = 430 for screen width = 1526
    console.log($(window).width());
    /* var seedrandom = require('seedrandom');
    var rng = seedrandom('hello.'); */
    console.log("Wordcloud loaded"); 
    var wordcloud_results = this.calculateWordCloudData(this.props.projection_data, this.props.original_data,this.props.cluster);
    return (
        
        <div  id={theid} style={{border:"1px solid rgb(188,188,188)"}} >
        {/* <WordCloud data={wordcloud_results[0]} fill={wordcloud_results[1]}  /> */}
       <WordCloud  data={wordcloud_results[0]} fill={"black"} rotate={0} padding={8} height={myheight} />
        </div> 
        
    )
  }// end of render
} //end of class


        {/* <svg  id={theid} style={{border:"1px solid rgb(188,188,188)"}} ></svg> */}